const state = {
    languageStr: '',
    languageVisible:false
}

const mutations = {
    SET_LANGUAGE: (state, languageStr) => {
        state.languageStr = languageStr
    },
    SET_LANGUAGE_VISIBLE: (state, languageVisible) => {
        state.languageVisible = languageVisible
    },
}
const actions = {
    setLanguage({commit}, languageStr){
        commit('SET_LANGUAGE', languageStr)
    },
    setLanguageVisible({commit}, languageVisible){
        commit('SET_LANGUAGE_VISIBLE', languageVisible)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
