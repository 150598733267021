export const m = {
    test: "English",
    language: "English",
    cancel: "Cancel",
    ok: "OK",
}
export const home = {
    language: "Language",
    qq_login_btn: "Login",
    product_introduction: "Product Introduction",
    quick_to_use: "Quick to Use",
    contact_us: "Contact Us",
    help_documentation: "Help Documentation",
    title: "Minecraft Custom Logic Low-Code Platform",
    sub_title: "Powerful customization options, supporting numerous methods, achieve complex logic through a low-code platform, simplifying cumbersome processes",
    banner: "/img/5DD046FE6DE6BCFA3433B823EF270491.c67392e8.png",
    app_creation: "App Creation",
    edit_the_module: "Edit the Module",
    plugin_builds: "Plugin Builds",
    operational_processes: "Operational Processes",
    enter_the_workbench: "Enter the Workbench"
}

export const pages = {
    go_back: "Go Back to Workbench",
    please_search_by_component_name: "Please enter component name to search",
    help: "Help",
    save: "Save",
    search_triggers: "Search Triggers",
    tips: "Tips",
    create_a_new_event_trigger: "Create a New Event Trigger",
    type: "Type",
    player_events: "Player Events",
    name: "Name",
    please_enter_a_first_name: "Please enter a name",
    remark: "Remark",
    please_enter: "Please enter",
    cancel: "Cancel",
    ok: "OK",
    create_a_PAPI_placeholder: "Create a PAPI Placeholder",
    it_can_be_obtained_by_identifying: "It can be obtained by identifying %ultrafunction_%",
    logo: "Logo",
    please_enter_a_logo: "Please enter a logo",
    next_save: "You have not saved yet, do you want to continue?",
    do_not_save: "Do Not Save",
    gen_error: "Code generation failed, please contact administrator",
    error_player_msg: "Invalid trigger, this method can only be used for Player Chat triggers",
    error_player_command: "Invalid trigger, this method can only be used for Player Command triggers",
    error_player_server: "Invalid trigger, this method can only be used for Server List triggers",
    error_player_move: "Invalid trigger, this method can only be used for Player Movement triggers",
    error_player_lang: "Invalid trigger, this method can only be used for Player Language Change triggers",
    error_player_kill: "Invalid trigger, this method can only be used for Player Death triggers",
    error_player_item: "Invalid trigger, this method can only be used for Successful Enchantment triggers",
    error_next_event: "You have not saved the current trigger, do you want to continue?",
    select_event_type: "Please select a trigger type",
    input_name: "Please enter a name",
    create_successfully: "Created Successfully",
    saved_successfully: "Saved Successfully",
    delete_success: "Deleted Successfully",
    delete_event: "This action will permanently delete",
    next_event: "the trigger, do you want to continue?"
}

export const workspace = {
    new: "New",
    import: "Import",
    recent_files: "Recent Files",
    my_favorites: "My Favorites",
    number_of_files: "Number of Files",
    upgrade: "Upgrade Now",
    search_for_files: "Search for Files",
    download: "Download Core",
    file: "File",
    recycle: "Recycle Bin",
    updated_on: "Updated On",
    construct: "Construct",
    remove: "Delete",
    edit: "Edit",
    rename: "Rename",
    share: "Share",
    unfavorite: "Unfavorite",
    favorites: "Favorites",
    create_file: "Create File",
    name: "Name",
    file_name: "Please enter file name",
    cancel: "Cancel",
    ok: "OK",
    share_files: "Share Files",
    share_code: "Share Code",
    copy: "Copy",
    import_file: "Import File",
    please_enter_the_sharing_code: "Please enter the sharing code",
    creation_successful: "Creation Successful",
    file_not_empty: "File name cannot be empty",
    function_not_yet_open: "Function not yet available",
    please_enter_the_file_name: "Please enter the file name",
    tips: "Tips",
    save_delete: "This action will permanently delete the file, do you want to continue?",
    shared_ultraFunction: "I shared a project with you on UltraFunction",
    The_sharing_code_is: "The sharing code is",
    the_replication_is_successful: "Copy successful",
    the_import_was_successful: "Import successful"
}

export const event = {
    event_now_tooltip: "Get current event",
    event_now_message: "Current event %1",
    event_set_cancelled_tooltip: "Cancel event",
    event_set_cancelled_message: "Cancel event: %1 Boolean: %2",
    event_get_locale_tooltip: "Get selected language, triggered when switching languages",
    event_get_locale_message: "Get selected language, triggered when switching languages %1",
    example_black_message: "Create empty list %1",
};

export const base = {
    symbol_tooltip: "Outputs a boolean value for two numeric values",
    symbol_message: "Value: %1 %2 %3 Value: %4",
    number_tooltip: "Create a number",
    number_message: "Number: %1 %2",
    string_tooltip: "Create a text",
    string_message: "Text: %1",
    to_string_tooltip: "Convert a number to a string",
    to_string_message: "Convert to string %1",
    boolean_tooltip: "Create a boolean type",
    boolean_message: "Boolean: %1 %2",
    concat_string_tooltip: "String concatenation",
    concat_string_message: "String concatenation String 1: %1 String 2: %2",
    not_tooltip: "Negate a boolean value",
    not_message: "Logical negation Boolean: %1",
    generate_uuid_tooltip: "Result is a string without '-'",
    generate_uuid_message: "Generate a random UUID string %1",
    sub_string_1_tooltip: "Corresponds to backend str.subString(num), from which position to start extracting",
    sub_string_1_message: "Extract substring String: %1 Starting position: %2",
    contains_string_tooltip: "Check if String A contains String B",
    contains_string_message: "Check if String A contains String B String A: %1 String B: %2",
    sub_string_2_tooltip: "Corresponds to backend str.subString(num, num), extract content from which to which position",
    sub_string_2_message: "Extract substring String: %1 Starting position: %2 Ending position: %3",
    replace_string_tooltip: "Replace occurrences of String B in String A with String C",
    replace_string_message: "Replace string Original string: %1 Replaced value: %2 Replacement value: %3",
    instance_of_tooltip: "Check if the data type is",
    instance_of_message: "Check if the data type is Any data: %1 Data type: %2",
    struct_type_tooltip: "Data type",
    struct_type_message: "Data type: %1 %2",
    get_struct_type_tooltip: "Get data type",
    get_struct_type_message: "Get data type Any data: %1",
    base_is_null_tooltip: "Pass any content to check if it\'s null",
    base_is_null_message: "Check if value is null Value: %1",
    base_operate_tooltip: "Arithmetic operations, can add, subtract, multiply, divide numeric values",
    base_operate_message: "Number: %1 %2 %3 Number: %4",
    base_abs_tooltip: "Get the absolute value of a number",
    base_abs_message: "Get absolute value Value: %1",
    base_remainder_tooltip: "A divided by B, remainder result",
    base_remainder_message: "Get remainder Value A: %1 Value B: %2",
    base_random_number_tooltip: "Get a random number within a range, starting from 0; if the input is 2, the result will be either 0 or 1, as an integer",
    base_random_number_message: "Get a random number within a range Range: %1",
    base_and_or_tooltip: "And or",
    base_and_or_message: "Boolean: %1 %2 %3 Boolean: %4",
    base_create_list_tooltip: "Create an empty List collection",
    base_create_list_message: "Create an empty List collection",
    base_list_add_tooltip: "Add an element to the List collection",
    base_list_add_message: "Add an element to the List collection List: %1 Value: %2",
    base_list_remove_element_tooltip: "Remove an element from the List collection",
    base_list_remove_element_message: "Remove an element from the List collection List: %1 Value: %2",
    base_list_remove_index_tooltip: "Remove the element at a specific index from the List collection",
    base_list_remove_index_message: "Remove the element at a specific index from the List collection List: %1 Index: %2",
    base_list_contains_tooltip: "Check if an element exists in the List collection",
    base_list_contains_message: "Check if an element exists in the List collection List: %1 Value: %2",
    base_list_get_tooltip: "Get the element at a specific index from the List collection",
    base_list_get_message: "Get the element at a specific index from the List collection List: %1 Index: %2",
    base_list_is_empty_tooltip: "Check if the List collection is empty",
    base_list_is_empty_message: "Check if the List collection is empty List: %1",
    base_list_clear_tooltip: "Clear the List collection",
    base_list_clear_message: "Clear the List collection List: %1",
    base_list_size_tooltip: "Get the size of the List collection",
    base_list_size_message: "Get the size of the List collection List: %1",
    base_create_map_tooltip: "Create an empty Map",
    base_create_map_message: "Create an empty Map",
    base_map_put_num_tooltip: "Add a data entry to the Map (with a numeric key)",
    base_map_put_num_message: "Add a data entry to the Map (with a numeric key) Map: %1 Key: %2 Value: %3",
    base_map_put_str_tooltip: "Add a data entry to the Map (with a string key)",
    base_map_put_str_message: "Add a data entry to the Map (with a string key) Map: %1 Key: %2 Value: %3",
    base_map_remove_num_tooltip: "Remove a data entry from the Map (with a numeric key)",
    base_map_remove_num_message: "Remove a data entry from the Map (with a numeric key) Map: %1 Key: %2",
    base_map_remove_str_tooltip: "Remove a data entry from the Map (with a string key)",
    base_map_remove_str_message: "Remove a data entry from the Map (with a string key) Map: %1 Key: %2",
    base_map_get_num_tooltip: "Get a data entry from the Map (with a numeric key)",
    base_map_get_num_message: "Get a data entry from the Map (with a numeric key) Map: %1 Key: %2",
    base_map_get_str_tooltip: "Get a data entry from the Map (with a string key)",
    base_map_get_str_message: "Get a data entry from the Map (with a string key) Map: %1 Key: %2",
    base_map_contains_key_num_tooltip: "Check if the Map contains a specific key (with a numeric key)",
    base_map_contains_key_num_message: "Check if the Map contains a specific key (with a numeric key) Map: %1 Key: %2",
    base_map_contains_key_str_tooltip: "Check if the Map contains a specific key (with a string key)",
    base_map_contains_key_str_message: "Check if the Map contains a specific key (with a string key) Map: %1 Key: %2",
    base_map_clear_tooltip: "Clear the Map",
    base_map_clear_message: "Clear the Map Map: %1",
    base_map_key_set_tooltip: "Get all key sets in the Map",
    base_map_key_set_message: "Get all key sets in the Map Map: %1",
    base_map_values_tooltip: "Get all value sets in the Map",
    base_map_values_message: "Get all value sets in the Map Map: %1",
    base_map_is_empty_tooltip: "Check if the Map is empty",
    base_map_is_empty_message: "Check if the Map is empty Map: %1",
    base_map_size_tooltip: "Get the size of the Map",
    base_map_size_message: "Get the size of the Map Map: %1",
    base_set_papi_result_tooltip: "Set PAPI placeholder result",
    base_set_papi_result_message: "Set PAPI placeholder result Value: %1",
    base_return_1_tooltip: "End this operation, the method will not continue to execute",
    base_return_1_message: "Return",
    base_break_tooltip: "Break",
    base_break_message: "Break",
    base_continue_tooltip: "Continue",
    base_continue_message: "Continue",


    // Dropdown options
    options: {
        equal: "=",
        less_than: "<",
        greater_than: ">",
        less_than_or_equal: "<=",
        greater_than_or_equal: ">=",
        true: "True",
        false: "False",
        long: "Long",
        double: "Double",
        string: "String",
        boolean: "Boolean",
        list: "List",
        map: "Map",
        player: "Player",
        location: "Location",
        world: "World",
        bossBar: "BossBar",
        scoreboard: "Scoreboard",
        team: "Team",
        add: "Add",
        subtract: "Subtract",
        multiply: "Multiply",
        divide: "Divide",
        and: "And",
        or: "Or",
    }
};

export const block = {
    block_get_block_tooltip: "Get block via event",
    block_get_block_message: "Get block via event Event: %1",
    block_set_type_tooltip: "Set block type",
    block_set_type_message: "Set block type Block: %1 Type: %2",
    block_get_type_tooltip: "Get block type",
    block_get_type_message: "Get block type Block: %1",
    block_get_block_location_tooltip: "Get block by coordinates",
    block_get_block_location_message: "Get block by coordinates Coordinates: %1",
    block_break_naturally_tooltip: "Break block",
    block_break_naturally_message: "Break block Block: %1",
};

export const bossBar = {
    boss_bar_create_boss_bar_tooltip: "Create a boss bar",
    boss_bar_create_boss_bar_message: "Create a boss bar Title: %1 Color: %2 Type: %3 Progress: %4",
    boss_bar_add_boss_bar_tooltip: "Display a boss bar to a specific player",
    boss_bar_add_boss_bar_message: "Display a boss bar to a specific player Player: %1 Boss bar: %2",
    boss_bar_remove_boss_bar_tooltip: "Remove a boss bar from a player",
    boss_bar_remove_boss_bar_message: "Remove a boss bar from a player Player: %1 Boss bar: %2",
    boss_bar_set_progress_message: "Set the progress of the boss bar Boss bar: %1 Range 0 ~ 1.0 Progress: %2",
    boss_bar_set_visible_tooltip: "Set the visibility of the boss bar",
    boss_bar_set_visible_message: "Set the visibility of the boss bar Boss bar: %1 Boolean: %2",
    boss_bar_set_color_tooltip: "Set the color of the boss bar",
    boss_bar_set_color_message: "Set the color of the boss bar Boss bar: %1 Color: %2",
    boss_bar_set_style_tooltip: "Set the type of the boss bar",
    boss_bar_set_style_message: "Set the type of the boss bar Boss bar: %1 Type: %2",
    boss_bar_set_title_tooltip: "Set the title of the boss bar",
    boss_bar_set_title_message: "Set the title of the boss bar Boss bar: %1 Title: %2",
    boss_bar_set_remove_all_tooltip: "Remove the boss bar from all players",
    boss_bar_set_remove_all_message: "Remove the boss bar from all players Boss bar: %1",
};

export const data_source = {
    put_cache_tooltip: "Can store any value",
    put_cache_message: "Store data in the global variable cache Key: %1 Value: %2",
    get_cache_tooltip: "Returns a generic type",
    get_cache_message: "Retrieve data from the global variable cache Key: %1",
    remove_cache_tooltip: "",
    remove_cache_message: "Remove data from the global variable cache Key: %1",
    put_data_base_tooltip: "",
    put_data_base_message: "Store data in the database Key: %1 Value: %2",
    get_data_base_tooltip: "Returns a generic type",
    get_data_base_message: "Retrieve data from the database Key: %1",
    remove_data_base_tooltip: "",
    remove_data_base_message: "Remove data from the database Key: %1",
    contains_key_cache_tooltip: "Check if data exists in the global variable cache",
    contains_key_cache_message: "Check if data exists in the global variable cache Key: %1",
    contains_key_database_tooltip: "Check if data exists in the database",
    contains_key_database_message: "Check if data exists in the database Key: %1",
    get_string_from_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    get_string_from_config_message: "Retrieve a string value from the config file Key: %1",
    get_long_from_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    get_long_from_config_message: "Retrieve an integer value from the config file Key: %1",
    get_double_from_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    get_double_from_config_message: "Retrieve a decimal value from the config file Key: %1",
    get_boolean_from_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    get_boolean_from_config_message: "Retrieve a boolean value from the config file Key: %1",
    put_string_2_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    put_string_2_config_message: "Store a string value in the config file Key: %1 Value: %2",
    put_long_2_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    put_long_2_config_message: "Store an integer value in the config file Key: %1 Value: %2",
    put_double_2_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    put_double_2_config_message: "Store a decimal value in the config file Key: %1 Value: %2",
    put_boolean_2_config_tooltip: "Key value is the path in the config file, e.g., A.B.C.D",
    put_boolean_2_config_message: "Store a boolean value in the config file Key: %1 Value: %2",
    get_string_list_from_config_tooltip: "Get a list of strings from the config file",
    get_string_list_from_config_message: "Get a list of strings from the config file Key: %1",
    get_long_list_from_config_tooltip: "Get a list of integers from the config file",
    get_long_list_from_config_message: "Get a list of integers from the config file Key: %1",
    get_double_list_from_config_tooltip: "Get a list of decimals from the config file",
    get_double_list_from_config_message: "Get a list of decimals from the config file Key: %1"
};


export const field = {
    create_player_tooltip: "Create a variable to store a player object",
    create_player_message: "Create player variable   Variable name: %1 %2 Access method: %3",
    get_player_tooltip: "Retrieve the player object from the variable",
    get_player_message: "Get player variable   Variable name: %1 %2",
    create_string_tooltip: "String variable",
    create_string_message: "Create string variable   Variable name: %1 %2 Access method: %3",
    get_string_tooltip: "Retrieve the string variable",
    get_string_message: "Get string variable %1 %2",
    create_number_tooltip: "Create a number variable",
    create_number_message: "Create number variable  Variable name: %1 %2 Access method: %3",
    get_number_tooltip: "Retrieve the number variable",
    get_number_message: "Get number variable Variable name: %1 %2",
    create_boolean_tooltip: "Create a boolean variable",
    create_boolean_message: "Create boolean variable Variable name %1 %2 Access method %3",
    get_boolean_tooltip: "Retrieve the boolean variable",
    get_boolean_message: "Get boolean variable Variable name: %1 %2",
    create_location_tooltip: "Create a location variable",
    create_location_message: "Create location variable Variable name: %1 %2 Access method: %3",
    get_location_tooltip: "Retrieve the location variable",
    get_location_message: "Get location variable Variable name: %1 %2",
    create_world_tooltip: "Create a world variable",
    create_world_message: "Create world variable Variable name: %1 %2 Access method: %3",
    get_world_tooltip: "Retrieve the world variable",
    get_world_message: "Get world variable Variable name: %1 %2",
    create_boss_bar_tooltip: "Create a bossbar variable",
    create_boss_bar_message: "Create bossbar variable Variable name: %1 %2 Access method: %3",
    get_boss_bar_tooltip: "Retrieve the bossbar variable",
    get_boss_bar_message: "Get bossbar variable Variable name: %1 %2",
    create_scoreboard_tooltip: "Create a scoreboard variable",
    create_scoreboard_message: "Create scoreboard variable Variable name: %1 %2 Access method: %3",
    get_scoreboard_tooltip: "Retrieve the scoreboard variable",
    get_scoreboard_message: "Get scoreboard variable Variable name: %1 %2",
    create_list_tooltip: "Create a list collection variable",
    create_list_message: "Create list collection variable Variable name: %1 %2 Access method: %3",
    get_list_tooltip: "Retrieve the list collection variable",
    get_list_message: "Get list collection variable Variable name: %1 %2",
    create_map_tooltip: "Create a map variable",
    create_map_message: "Create map variable Variable name: %1 %2 Access method: %3",
    get_map_tooltip: "Retrieve the map variable",
    get_map_message: "Get map variable Variable name: %1 %2",
    create_team_tooltip: "Create a team variable",
    create_team_message: "Create team variable Variable name: %1 %2 Access method: %3",
    get_team_tooltip: "Retrieve the team variable",
    get_team_message: "Get team variable Variable name: %1 %2",
    create_block_tooltip: "Create a block variable",
    create_block_message: "Create block variable Variable name: %1 %2 Access method: %3",
    get_block_tooltip: "Retrieve the block variable",
    get_block_message: "Get block variable Variable name: %1 %2"
};

export const translations = {
    function_block: {
        tooltip: "This is a function, where you can add event blocks",
        message0: "Function Name %1 %2 Description %3 %4 Enabled %5 %6 %7"
    },
    trigger_block: {
        tooltip: "Current trigger",
        message0: "Current trigger %1 Place your logic within this block %2 Help page: https://ultrafunction.domcer.com/wiki %3 %4"
    },
    trigger_block_papi: {
        tooltip: "PAPI Placeholder",
        message0: "PAPI Placeholder %1 Accessed via %ultrafunction_identifier% %2 Place your logic within this block %3 Help page: https://ultrafunction.domcer.com/wiki %4 %5 %6 Return result: %7"
    }
};

export const location = {
    create_1_tooltip: "Create coordinates",
    create_1_message0: "Create coordinates X: %1 Y: %2 Z: %3",
    create_2_tooltip: "Create coordinates",
    create_2_message0: "Create coordinates X: %1 Y: %2 Z: %3 Yaw: %4 Pitch: %5",
    create_3_tooltip: "Create coordinates",
    create_3_message0: "Create coordinates World: %1 X: %2 Y: %3 Z: %4",
    create_4_tooltip: "Create coordinates",
    create_4_message0: "Create coordinates World: %1 X: %2 Y: %3 Z: %4 Yaw: %5 Pitch: %6",
    get_x_tooltip: "Get X axis",
    get_x_message0: "Get X axis Coordinates: %1",
    get_y_tooltip: "Get Y axis",
    get_y_message0: "Get Y axis Coordinates: %1",
    get_z_tooltip: "Get Z axis",
    get_z_message0: "Get Z axis Coordinates: %1",
    get_yaw_tooltip: "Get horizontal angle",
    get_yaw_message0: "Get horizontal angle Coordinates: %1",
    get_pitch_tooltip: "Get vertical angle",
    get_pitch_message0: "Get vertical angle Coordinates: %1",
    get_to_tooltip: "Get movement endpoint, only for Player Movement Trigger",
    get_to_message0: "Get movement endpoint %1",
    get_from_tooltip: "Get movement starting point, only for Player Movement Trigger",
    get_from_message0: "Get movement starting point %1",
    get_block_tooltip: "Get block coordinates",
    get_block_message0: "Get block coordinates Block: %1"
};

export const logical_control = {
    if_tooltip: "Conditional control statement",
    if_message0: "If %1 Then %2",
    if_else_tooltip: "Conditional statement with an else clause",
    if_else_message0: "If %1 Then %2 Else %3",
    repeat_do_tooltip: "Repeat a task a certain number of times",
    repeat_do_message0: "Repeat Times: %1 Do Task %2 %3",
    while_do_tooltip: "Repeat a task while a condition is met",
    while_do_message0: "While Condition Boolean: %1 Continuously Do Task %2 %3",
    timer_1_tooltip: "Delay task execution by a specified number of ticks",
    timer_1_message0: "One-time Timer Delay in Ticks: %1 Do Task %2 %3",
    timer_2_tooltip: "Delay and periodically repeat a task at specified tick intervals",
    timer_2_message0: "Recurring Timer Delay in Ticks: %1 Interval in Ticks: %2 Do Task %3 %4",
};


export const message = {
    player_send_message_tooltip: "Send a message to a player",
    player_send_message_message0: "Send a %2 message to %1 %3",
    player_send_message_all_tooltip: "Send a message to all players",
    player_send_message_all_message0: "Send a %2 message to all players %1 %3",
    set_join_message_tooltip: "Set the server join message",
    set_join_message_message0: "Set join message %1",
    set_quit_message_tooltip: "Set the server quit message",
    set_quit_message_message0: "Set quit message %1",
    set_chat_format_tooltip: "Only available for player chat events",
    set_chat_format_message0: "Set chat format %1",
    get_chat_message_tooltip: "Retrieve chat content",
    get_chat_message_message0: "Retrieve chat content %1",
    get_command_tooltip: "Retrieve command content",
    get_command_message0: "Retrieve command content %1",
    get_papi_result_tooltip: "PAPI placeholder, e.g., %server_tps%. Use the method below if the placeholder is related to a player variable",
    get_papi_result_message0: "Retrieve PAPI variable result Placeholder: %1",
    get_papi_result_player_tooltip: "PAPI placeholder, e.g., %player_name%",
    get_papi_result_player_message0: "Retrieve PAPI variable result Placeholder: %1 Player: %2"
};

export const scoreboard = {
    create_tooltip: "The rows should be in JSON string format, e.g., {\"123\",\"123\"}",
    create_message0: "Create a scoreboard Title: %1 Rows: %2",
    create_list_tooltip: "Create a scoreboard",
    create_list_message0: "Create a scoreboard Title: %1 List: %2",
    set_scoreboard_tooltip: "Assign a scoreboard to a player",
    set_scoreboard_message0: "Set a scoreboard for player Player: %1 Scoreboard: %2",
    reset_scoreboard_tooltip: "Reset a player\'s scoreboard",
    reset_scoreboard_message0: "Reset scoreboard for player Player: %1"
};

export const server = {
    set_motd_tooltip: "Set the server MOTD (Message of the Day). Only applicable in server list events.",
    set_motd_message0: "Set server MOTD Event: %1 First Line: %2 Second Line: %3",
    dispatch_command_tooltip: "Dispatch a command to the console",
    dispatch_command_message0: "Force the console to execute a command Command: %1",
    get_online_players_size_tooltip: "Get the current number of online players",
    get_online_players_size_message0: "Retrieve the current number of online players"
};


export const team = {
    create_team_tooltip: "Create a team on the server's main scoreboard",
    create_team_message0: "Create a team on the server's main scoreboard Team name: %1",
    create_team_scoreboard_tooltip: "Create a team on a specified scoreboard",
    create_team_scoreboard_message0: "Create a team on a specified scoreboard Scoreboard: %1 Team name: %2",
    get_team_tooltip: "Get a team from the server's main scoreboard",
    get_team_message0: "Get a team from the server's main scoreboard Team name: %1",
    get_team_scoreboard_tooltip: "Get a team from a specified scoreboard",
    get_team_scoreboard_message0: "Get a team from a specified scoreboard Scoreboard: %1 Team name: %2",
    get_player_team_tooltip: "Get a player's team from the server's main scoreboard",
    get_player_team_message0: "Get a player's team from the server's main scoreboard Player: %1",
    get_player_team_scoreboard_tooltip: "Get a player's team from a specified scoreboard",
    get_player_team_scoreboard_message0: "Get a player's team from a specified scoreboard Scoreboard: %1 Player: %2",
    add_player_2_team_tooltip: "Add a player to a team on the main scoreboard",
    add_player_2_team_message0: "Add a player to a team on the main scoreboard Player: %1 Team name: %2",
    add_player_2_team_team_tooltip: "Add a player to a team",
    add_player_2_team_team_message0: "Add a player to a team Player: %1 Team instance: %2",
    is_player_in_team_tooltip: "Check if a player is in a team on the main scoreboard",
    is_player_in_team_message0: "Check if a player is in a team on the main scoreboard Player: %1 Team name: %2",
    is_player_in_team_team_tooltip: "Check if a player is in a team",
    is_player_in_team_team_message0: "Check if a player is in a team Player: %1 Team instance: %2",
    remove_player_4_team_tooltip: "Remove a player from a team on the main scoreboard",
    remove_player_4_team_message0: "Remove a player from a team on the main scoreboard Player: %1 Team name: %2",
    remove_player_4_team_team_tooltip: "Remove a player from a team",
    remove_player_4_team_team_message0: "Remove a player from a team Player: %1 Team instance: %2",
    delete_team_tooltip: "Delete a team from the server's main scoreboard",
    delete_team_message0: "Delete a team from the server's main scoreboard Team name: %1",
    delete_team_team_tooltip: "Delete a team",
    delete_team_team_message0: "Delete a team Team instance: %1",
    get_color_tooltip: "Get the team's color",
    get_color_message0: "Get the team's color Team instance: %1",
    get_entries_tooltip: "Get all entries in the team; a team can store players as well as other entries",
    get_entries_message0: "Get all entries in the team Team instance: %1",
    get_display_name_tooltip: "Get the team's display name",
    get_display_name_message0: "Get the team's display name Team instance: %1",
    get_name_tag_visibility_tooltip: "Get the type of name tag visibility for the team",
    get_name_tag_visibility_message0: "Get the type of name tag visibility for the team Team instance: %1",
    get_name_tooltip: "Get the team's name",
    get_name_message0: "Get the team's name Team instance: %1",
    get_player_tooltip: "Get all players in the team",
    get_player_message0: "Get all players in the team Team instance: %1",
    get_prefix_tooltip: "Get the team's prefix",
    get_prefix_message0: "Get the team's prefix Team instance: %1",
    get_suffix_tooltip: "Get the team's suffix",
    get_suffix_message0: "Get the team's suffix Team instance: %1",
    get_scoreboard_tooltip: "Get the team's scoreboard",
    get_scoreboard_message0: "Get the team's scoreboard Team instance: %1",
    get_size_tooltip: "Get the team's size",
    get_size_message0: "Get the team's size Team instance: %1",
    set_allow_friendly_fire_tooltip: "Set whether team members can attack each other",
    set_allow_friendly_fire_message0: "Set whether team members can attack each other Team instance: %1 Boolean: %2",
    set_can_see_friendly_invisible_tooltip: "Set whether team members can see teammates who are invisible",
    set_can_see_friendly_invisible_message0: "Set whether team members can see teammates who are invisible Team instance: %1 Boolean: %2",
    set_color_tooltip: "Set the team's color",
    set_color_message0: "Set the team's color Team instance: %1 Value: %2",
    set_display_name_tooltip: "Set the team's display name",
    set_display_name_message0: "Set the team's display name Team instance: %1 Value: %2",
    set_name_tag_visibility_tooltip: "Set the type of name tag visibility for the team",
    set_name_tag_visibility_message0: "Set the type of name tag visibility for the team Team instance: %1 Value: %2",
    set_prefix_tooltip: "Set the team's prefix",
    set_prefix_message0: "Set the team's prefix Team instance: %1 Value: %2",
    set_suffix_tooltip: "Set the team's suffix",
    set_suffix_message0: "Set the team's suffix Team instance: %1 Value: %2"
};

export const world = {
    player_get_world_tooltip: "Get the world the player is in",
    player_get_world_message0: "Get the world the player is in Player: %1",
    get_world_tooltip: "Get world instance",
    get_world_message0: "Get world instance World name: %1",
    spawn_experience_tooltip: "Spawn experience orbs. Must be 1 or more, otherwise no experience will be spawned",
    spawn_experience_message0: "Spawn experience orbs Coordinates: %1 Experience amount: %2",
    spawn_firework_tooltip: "Spawn a basic firework at the coordinates. Future versions will allow custom firework settings",
    spawn_firework_message0: "Spawn firework Coordinates: %1"
};


export const player = {
    get_event_tooltip: "Obtain a player instance through an event",
    get_event_message0: "Get player instance Event: %1",
    get_world_tooltip: "Get the world the player is in",
    get_world_message0: "Get the world the player is in Player: %1",
    get_player_tooltip: "Get a player instance by player name",
    get_player_message0: "Get a player instance by player name Player name: %1",
    get_enchanter_tooltip: "Get a player instance through an enchantment event. Can only be used with the item enchantment success trigger",
    get_enchanter_message0: "Get player instance through enchantment event Event: %1",
    get_health_tooltip: "Get the player's health",
    get_health_message0: "Get player's health Player: %1",
    set_health_tooltip: "Set a player's health",
    set_health_message0: "Set player's health Player: %1 Health: %2",
    get_name_tooltip: "Get player's name from player instance",
    get_name_message0: "Get player's name Player: %1",
    has_permission_tooltip: "Check if the player has permission",
    has_permission_message0: "Check if the player has permission Player: %1 Permission: %2",
    set_fly_tooltip: "Set player's flying status",
    set_fly_message0: "Set flying Player: %1 Status: %2",
    send_title_tooltip: "Send a title to the player",
    send_title_message0: "Send to Player: %1 a title %2 Main title: %3 %4 Subtitle: %5 %6 Time below in ticks, 20 ticks per second %7 Title fade-in time: %8 Ticks %9 %10 Title display duration: %11 Ticks %12 %13 Title fade-out time: %14 Ticks %15",
    reset_title_tooltip: "Reset title",
    reset_title_message0: "Reset title Player: %1",
    get_location_tooltip: "Get coordinates from player",
    get_location_message0: "Get player's world coordinates Player: %1",
    get_unique_id_tooltip: "Get player's UUID",
    get_unique_id_message0: "Get player's UUID Player: %1",
    set_respawn_location_tooltip: "Set player's respawn point",
    set_respawn_location_message0: "Set player's respawn point Player: %1 Coordinates: %2",
    get_max_health_tooltip: "Get player's maximum health",
    get_max_health_message0: "Get player's maximum health Player: %1",
    get_level_tooltip: "Get player's level",
    get_level_message0: "Get player's level Player: %1",
    set_level_tooltip: "Set player's level",
    set_level_message0: "Set player's level Player: %1 Level: %2",
    get_fly_speed_tooltip: "Get player's flying speed",
    get_fly_speed_message0: "Get player's flying speed Player: %1",
    set_fly_speed_tooltip: "Set player's flying speed",
    set_fly_speed_message0: "Set player's flying speed Player: %1 Speed: %2",
    get_ping_tooltip: "Get player's latency",
    get_ping_message0: "Get player's latency Player: %1",
    get_food_level_tooltip: "Get player's food level",
    get_food_level_message0: "Get player's food level Player: %1",
    set_food_level_tooltip: "Set player's food level",
    set_food_level_message0: "Set player's food level Player: %1 Food level: %2",
    get_allow_flight_tooltip: "Check if player is allowed to fly",
    get_allow_flight_message0: "Check if player is allowed to fly Player: %1",
    get_bed_location_tooltip: "Get player's bed location",
    get_bed_location_message0: "Get player's bed location Player: %1 %2 If the player's bed is not present, the result is null %3",
    get_attack_cooldown_tooltip: "Get player's attack cooldown (game version 1.9+)",
    get_attack_cooldown_message0: "Get player's attack cooldown (game version 1.9+) Player: %1",
    check_is_op_tooltip: "Check if the player is an OP",
    check_is_op_message0: "Check if the player is an OP Player: %1",
    teleport_tooltip: "Teleport the player to a coordinate",
    teleport_message0: "Teleport player to a coordinate Player: %1 Coordinates: %2",
    set_max_health_tooltip: "Set maximum health",
    set_max_health_message0: "Set maximum health Player: %1 Health: %2",
    get_list_name_tooltip: "Get name in the Tab list",
    get_list_name_message0: "Get name in the Tab list Player: %1",
    set_list_name_tooltip: "Set name in the Tab list",
    set_list_name_message0: "Set name in the Tab list Player: %1 Name: %2",
    set_weather_tooltip: "Set player's own weather, can be different from the current world weather",
    set_weather_message0: "Set weather Player: %1 Weather: %2",
    reset_weather_tooltip: "Reset player's own weather to match the current world weather",
    reset_weather_message0: "Reset weather Player: %1",
    set_time_tooltip: "1 second is 20 ticks",
    set_time_message0: "Set time Player: %1 Tick: %2 %3",
    set_time2_tooltip: "If relative time is true, the player's time will change with the world's time and maintain a difference. Otherwise, the client's time does not change. Note! If relative time is true, then time represents the difference between the client's time and the world's time (relative to the world's time), if false, then time represents an absolute time.",
    set_time2_message0: "Set time Player: %1 Tick: %2 %3 Is relative time: %4",
    reset_time_tooltip: "Sync player's time with the current world time",
    reset_time_message0: "Reset time Player: %1",
    get_walk_speed_tooltip: "Get walking speed",
    get_walk_speed_message0: "Get walking speed Player: %1",
    set_walk_speed_tooltip: "Speed range is -1 to 1",
    set_walk_speed_message0: "Set walking speed Player: %1 Speed: %2 %3",
    set_can_pickup_items_tooltip: "Set if the player can pick up items",
    set_can_pickup_items_message0: "Set if the player can pick up items Player: %1 Allow: %2",
    close_inventory_tooltip: "Close inventory window",
    close_inventory_message0: "Close inventory window Player: %1",
    spawn_particle_tooltip: "Spawn particle effect",
    spawn_particle_message0: "Spawn particle Player: %1 Particle effect %2 X: %3 Y: %4 Z: %5 Quantity: %6",
    spawn_particle_location_tooltip: "Spawn particle",
    spawn_particle_location_message0: "Spawn particle Player: %1 Particle effect: %2 Location: %3 Quantity: %4",
    set_list_header_tooltip: "Set the header content of the TAB list",
    set_list_header_message0: "Set the header content of TAB list Player: %1 Content: %2",
    set_list_footer_tooltip: "Set the footer content of the TAB list",
    set_list_footer_message0: "Set the footer content of TAB list Player: %1 Content: %2",
    set_list_header_footer_tooltip: "Set the header and footer content of the TAB list",
    set_list_header_footer_message0: "Set the header and footer content of TAB list Player: %1 Header: %2 Footer: %3",
    kick_tooltip: "Kick out player",
    kick_message0: "Kick out Player: %1 Reason: %2",
    sudo_chat_tooltip: "Force a player to send a chat message or command (add \"/\" to the content for commands)",
    sudo_chat_message0: "Force a player to send a message or command Player: %1 Content: %2",
    set_absorption_amount_tooltip: "",
    set_absorption_amount_message0: "Set absorption amount Player: %1 Value: %2",
    set_invisible_tooltip: "Set visibility",
    set_invisible_message0: "Set visibility Player: %1 Visible: %2",
    is_invisible_tooltip: "Check if visible",
    is_invisible_message0: "Check if visible Player: %1",
    is_in_water_tooltip: "Check if player is in water",
    is_in_water_message0: "Check if in water Player: %1",
    send_sound_tooltip: "Play sound",
    send_sound_message0: "Play sound Player: %1 Sound: %2 Volume: %3 Pitch: %4",
    get_game_mode_tooltip: "Get player's game mode",
    get_game_mode_message0: "Get player's game mode Player: %1",
    set_game_mode_tooltip: "Set player's",
    set_game_mode_message0: "Set player's game mode Player: %1 Game mode: %2",
    potion_effect_tooltip: "Add potion effect to player",
    potion_effect_message0: "Add potion effect to player Player: %1 Potion effect: %2 Duration: %3 Level: %4",
    set_glowing_tooltip: "Set glowing",
    set_glowing_message0: "Set glowing Player: %1 Boolean: %2",
    give_exp_tooltip: "Give experience to player",
    give_exp_message0: "Give experience to player Player: %1 Experience: %2",
    give_exp_levels_tooltip: "Give levels to player",
    give_exp_levels_message0: "Give levels to player Player: %1 Levels: %2",
    set_compass_target_tooltip: "Set player's compass target",
    set_compass_target_message0: "Set player's compass target Player: %1 Coordinates: %2",
    hide_player_tooltip: "playerA, playerB, player A will not see player B",
    hide_player_message0: "Hide player B from player A's view Player A: %1 Player B: %2",
    show_player_tooltip: "playerA, playerB, player A will see player B",
    show_player_message0: "Show player B to player A Player A: %1 Player B: %2",
    set_sleep_ignored_tooltip: "If set to 'Yes', the player doesn't need to sleep, and others can skip the night",
    set_sleep_ignored_message0: "Set if sleeping ignores player Player: %1 Boolean: %2",
    is_sleeping_ignored_tooltip: "Check if sleeping ignores player",
    is_sleeping_ignored_message0: "Check if sleeping ignores player Player: %1",
    is_sleeping_tooltip: "Check if player is sleeping",
    is_sleeping_message0: "Check if player is sleeping Player: %1",
    set_total_experience_tooltip: "Set player's total current experience",
    set_total_experience_message0: "Set player's total current experience Player: %1 Experience: %2",
    get_total_experience_tooltip: "Get player's total experience",
    get_total_experience_message0: "Get player's total experience Player: %1",
    add_item_tooltip: "Give item to player",
    add_item_message0: "Give item to player Player: %1 Item: %2 Quantity: %3",
    set_player_glow_4_receiver_tooltip: "Player A glows, only visible to player B, requires ProtocolLib support",
    set_player_glow_4_receiver_message0: "Make player A glow visible only to player B Player A: %1 Player B: %2",
    remove_player_glow_4_receiver_tooltip: "Player A stops glowing, only visible to player B, requires ProtocolLib support",
    remove_player_glow_4_receiver_message0: "Make player A stop glowing visible only to player B Player A: %1 Player B: %2",
    set_velocity_tooltip: "Applies force to the player, can be used for jump pad-like effects",
    set_velocity_message0: "Set player's velocity Player: %1 X: %2 Y: %3 Z: %4",
    stp_tooltip: "Player, sub-server name, based on BungeeCord configuration",
    stp_message0: "Teleport player to sub-server Player: %1 Sub-server name: %2",
    give_balance_tooltip: "Give money to player",
    give_balance_message0: "Give money to player Player: %1 Amount: %2",
    take_balance_tooltip: "Take money from player",
    take_balance_message0: "Take money from player Player: %1 Amount: %2",
    get_balance_tooltip: "Get player's money amount",
    get_balance_message0: "Get player's money amount Player: %1",
    has_balance_tooltip: "Check if player has a relative amount of money",
    has_balance_message0: "Check if player has a relative amount of money Player: %1 Amount: %2",
    get_killer_tooltip: "Get the player who killed this player",
    get_killer_message0: "Get the player who killed this player Player: %1",
    set_actionbar_tooltip: "ActionBar is a temporary message. For persistent display, set up a timer to repeatedly call it. ActionBar messages typically display for 2-3 seconds.",
    set_actionbar_message0: "Set player's ActionBar Player:  %1 Content: %2",
    get_item_in_hand_tooltip: "Get the item currently in player's hand",
    get_item_in_hand_message0: "Get the item currently in player's hand Player: %1",
    get_display_name_tooltip: "Get player's display name",
    get_display_name_message0: "Get player's display name Player: %1",
    set_display_name_tooltip: "Set player's display name",
    set_display_name_message0: "Set player's display name Player: %1 Content: %2",
    get_papi_player_tooltip: "Get the player who triggered this PAPI placeholder",
    get_papi_player_message0: "Get the player who triggered this PAPI placeholder"
};

