<template>
  <div id="app">
    <router-view></router-view>
    <el-dialog
        :title="$t('m.language')"
        :visible.sync="languageVisible"
        width="20%"
        :before-close="handleClose">
      <el-select v-model="language" placeholder="请选择活动区域" style="width: 100%">
        <el-option label="简体中文" value="zh_CN"></el-option>
        <el-option label="English" value="en_US"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="languageVisible = false">{{ $t('m.cancel') }}</el-button>
        <el-button type="primary" @click="ok">{{ $t('m.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>


import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {
      language:localStorage.getItem('lang') || 'zh_CN'
    }
  },
  computed: {
    ...mapState("language", ["languageVisible"]),
  },
  methods: {
    ok(){
      this.setLanguageVisible(false);
      localStorage.setItem("lang",this.language);
      window.location.reload();
    },
    ...mapActions("language", ["setLanguageVisible"]),
    handleClose() {
      this.setLanguageVisible(false);
    }
  },
  created() {
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

</style>
