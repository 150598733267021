<template>
  <div class="blockly_box">
    <div class="blockly_header">
      <div class="blockly_header_left">
        <div class="blockly_header_left_title">
          <img alt="" src="~@/assets/img/LOGO3.png" style="height: 30px;cursor: pointer" @click="goHome">
          <div>
            <div class="goBack" @click="goBack" style="display: flex;align-items: center">
              <i class="el-icon-arrow-left"></i>
              <span style="font-size: 20px">{{ $t('pages.go_back') }}</span>
            </div>
            <span style="margin-left: 20px">{{ fileName }}</span>
          </div>
        </div>
      </div>

      <div class="blockly_header_right">
        <div style="margin-right: 30px">
          <el-input v-model="input" :placeholder="$t('pages.please_search_by_component_name')" size="mini" suffix-icon="el-icon-search"
                    @input="searchToolbox"
                    style="margin-left: 10px;">
          </el-input>
        </div>
        <div class="blockly_header_right_item">
          <img src="~@/assets/img/left-icon.png" height="20px">
          <img src="~@/assets/img/right-icon.png" height="20px" style="margin-left: 20px">
        </div>
        <a class="blockly_header_right_item blockly_header_right_item_helper"
           href="https://ultrafunction.domcer.com/wiki" style="color: #000" target="_blank">{{ $t('pages.help') }}</a>
        <div class="blockly_header_right_item">
          <el-button size="mini" type="primary" @click="saveToServe">
            <div style="display: flex;align-items: center">
              <img src="@/assets/img/save.png" style="height: 12px;" alt=""/>
              <div style="margin-left: 4px;font-size:18px">
                {{ $t('pages.save') }}
              </div>
            </div>
          </el-button>
        </div>
        <div class="blockly_header_right_item">
          <img alt=""
               :src="userInfo.img"
               width="40px" height="40px" style="border-radius: 50%"></img>
        </div>
      </div>
    </div>
    <div class="container-pages">
      <div style="" class="pages-box1">
        <div v-for="(item,index) in triggerTypeList" :key="index"
             :class="selSpace === index ?'pages-box1-item-sel' :''" class="pages-box1-item"
             style="display: flex;align-items: center;"
             @click="switchSpace(index)">
          <i class="el-icon-s-unfold" style="font-size: 26px"/>
          <div :style="item.css" style="margin-left: 3px;">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="pages-box2">
        <div style="padding: 10px;">
          <div
              style="width: 100%;height: 36px;background-color:rgb( 61,116,238);font-size: 14px;font-weight: bold;color: #fff;text-align: center;line-height: 36px;border-radius: 4px;cursor:pointer;"
              @click="openCreateTrigger">
            + {{ triggerTypeList[selSpace].nameCreate }}
          </div>
          <el-input v-model="searchTriggerName" :placeholder="$t('pages.search_triggers')" size="mini" suffix-icon="el-icon-search"
                    style="margin-top: 10px;">
          </el-input>
        </div>
        <div style="box-shadow: rgb(222, 224, 226) 0 2px 0;height: 1px">

        </div>
        <div style="padding: 10px" class="event-box">
          <div v-for="(item,index) in userTriggerList"
               :key="index"
               :class="triggerSelIndex === index ? 'event-box-item-sel' : ''"
               class="event-box-item" style="cursor: pointer" @click="choiceTrigger(index,item,$event)">
            <div>
              <div style="display: flex;align-items: center">
                <img v-if="triggerSelIndex !== index" height="14px"
                     src="@/assets/img/CAB217A5C186745BD0BE55537F70DE79.png"/>
                <img src="@/assets/img/B41019D3BE6396D6000F01CCF6A94BC6.png" height="14px" v-else/>
                <div :title="item.name" style="margin-left: 4px;display: inline-block;
overflow: hidden;
width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;">
                  {{ item.name }}
                </div>
              </div>
              <div>
                <i class="el-icon-delete-solid"></i>
              </div>
            </div>
          </div>
          <div class="event-box-item" style="cursor: pointer;opacity: 0">
            <div>
              <div style="display: flex;align-items: center">
                <img src="@/assets/img/B41019D3BE6396D6000F01CCF6A94BC6.png" height="14px"/>
                <div
                    style="margin-left: 4px;display: inline-block;overflow: hidden;width: 170px;text-overflow: ellipsis; white-space: nowrap;">
                </div>
              </div>
              <div>
                <i class="el-icon-delete-solid"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="blocklyDiv" class="pages-box3" ref="blocklyDiv" style="height: 100%;"></div>
      <!--      <div class="pages-box4">
              <div
                  style="display: flex;justify-content: space-between;padding: 20px;font-size: 13px;font-weight: bold;border-bottom: 2px solid rgb(243,243,243)">
                <div style="width: 50%;text-align: center">
                  组件
                  <div></div>
                </div>
                <div style="width: 50%;text-align: center;position: relative">
                  设置
                  <div style="position: absolute;left: 0;bottom: 0;height: 2px;background-color: rgb( 98, 98, 98)"></div>
                </div>
              </div>
              <div style="border-bottom: 2px solid rgb(243,243,243);padding:20px 20px 20px 10px">
                <el-input v-model="input" placeholder="请输入组件名称搜索" size="mini" suffix-icon="el-icon-search"
                          @input="searchToolbox"
                          style="margin-left: 10px;">
                </el-input>

              </div>
              <div id="customToolbox" class="custom-toolbox">
              </div>
            </div>-->
    </div>
    <el-dialog
        class="createDialog"
        :title="$t('pages.tips')"
        :visible.sync="createEvent"
        :show-close="false"
        width="30%">
      <div slot="title" style="box-shadow: rgb(222, 224, 226) 0 1px 0;">
        <div style="padding: 20px;display: flex;justify-content: space-between;align-items: center">
          <div>
            <i class="el-icon-document-remove"></i>
            {{ $t('pages.create_a_new_event_trigger') }}
          </div>
          <div style="cursor: pointer" @click="createEvent = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center">
        <div style="display: flex;align-items: center;width: 300px">
          <div style="width: 60px;font-weight: bold">
            <span style="color: red;margin-right: 5px">*</span>
            {{ $t('pages.type') }}
          </div>
          <el-select v-model="trigger.type" filterable :placeholder="$t('pages.player_events')" size="mini"
                     style="width: 100%;margin-left: 10px;">
            <el-option v-for="(item,index) in triggerComponentList" :key="index" :label="item.displayName"
                       :value="item.key"></el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <div style="display: flex;align-items: center;width: 300px">
          <div style="width: 60px;font-weight: bold">
            <span style="color: red;margin-right: 5px">*</span>
            {{ $t('pages.name') }}
          </div>
          <el-input v-model="trigger.name" :placeholder="$t('pages.please_enter_a_first_name')" size="mini" style="margin-left: 10px;">
          </el-input>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <div style="display: flex;align-items: flex-start;width: 300px">
          <span style="color: red;margin-right: 10px;opacity: 0;">*</span>
          <div style="width: 50px;font-weight: bold">{{ $t('pages.remark') }}</div>
          <el-input v-model="trigger.description" :rows="4" :placeholder="$t('pages.please_enter')" size="mini" style="margin-left: 5px;"
                    type="textarea">
          </el-input>
        </div>
      </div>
      <div slot="footer" style="box-shadow: rgb(222, 224, 226) 0 -1px 0 0;">
        <div style="padding: 20px;display: flex;justify-content: space-between;align-items: center">
          <div>
          </div>
          <div>
            <el-button size="mini" @click="createEvent = false">{{ $t('pages.cancel') }}</el-button>
            <el-button size="mini" type="primary" @click="createTrigger">{{ $t('pages.ok') }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        :show-close="false"
        :visible.sync="createPAPI"
        class="createDialog"
        :title="$t('pages.tips')"
        width="30%">
      <div slot="title" style="box-shadow: rgb(222, 224, 226) 0 1px 0;">
        <div style="padding: 20px;display: flex;justify-content: space-between;align-items: center">
          <div>
            <i class="el-icon-document-remove"></i>
            {{ $t('pages.create_a_PAPI_placeholder') }}
          </div>
          <div style="cursor: pointer" @click="createPAPI = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <div style="display: flex;align-items: center;width: 320px">
          <div style="width: 75px;font-weight: bold">
            <span style="color: red;margin-right: 5px">*</span>
            {{ $t('pages.name') }}
          </div>
          <el-input v-model="trigger.name" :placeholder="$t('pages.please_enter_a_first_name')" size="mini" style="margin-left: 10px;">
          </el-input>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <div style="display: flex;align-items: center;width: 320px">
          <div style="width: 75px;font-weight: bold;">
            <span style="color: red;margin-right: 5px">*</span>
            <el-tooltip class="item" :content="$t('pages.it_can_be_obtained_by_identifying')" effect="dark"
                        placement="bottom-start">
              <el-icon class="el-icon-question"></el-icon>
            </el-tooltip>
            {{ $t('pages.logo') }}
          </div>
          <el-input v-model="trigger.type" :placeholder="$t('pages.please_enter_a_logo')" size="mini" style="margin-left: 10px;">
          </el-input>
        </div>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <div style="display: flex;align-items: flex-start;width: 320px">
          <span style="color: red;margin-right: 10px;opacity: 0;">*</span>
          <div style="width: 64px;font-weight: bold">{{ $t('pages.remark') }}</div>
          <el-input v-model="trigger.description" :rows="4" :placeholder="$t('pages.please_enter')" size="mini" style="margin-left: 5px;"
                    type="textarea">
          </el-input>
        </div>
      </div>
      <div slot="footer" style="box-shadow: rgb(222, 224, 226) 0 -1px 0 0;">
        <div style="padding: 20px;display: flex;justify-content: space-between;align-items: center">
          <div>
          </div>
          <div>
            <el-button size="mini" @click="createPAPI = false">{{ $t('pages.cancel') }}</el-button>
            <el-button size="mini" type="primary" @click="createTrigger">{{ $t('pages.ok') }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--    <input type="text" id="toolboxSearch" placeholder="搜索积木..." @input="searchToolbox">-->

    <!--    <button @click="generateCode">生成代码</button>-->
    <!--    <button @click="saveCode">保存</button>-->
    <!--    <button @click="loadCode">加载</button>-->
    <!--    <pre>{{ generatedCode }}</pre>-->
    <!--    <br>-->
    <!--    <pre>保存的JSON:</pre>-->
    <!--    <pre>{{ saveCodeJson }}</pre>-->
  </div>
</template>
<style>
.pages-box1-item-sel {
  color: #2FA5FD;
}

.goBack {
  cursor: pointer;
}

.goBack:hover {
  color: #409EFF;
}

.createDialog > div {
  border-radius: 10px;
}

.createDialog .el-dialog__header {
  padding: 0;
}

.createDialog .el-dialog__footer {
  padding: 0;
}

.pages-box1-item {
  padding-left: 6px;
  border-left: 4px solid #666;
  margin-bottom: 10px;
  cursor: pointer;
}

.pages-box1-item-sel {
  padding-left: 6px;
  border-left: 4px solid #2FA5FD;
  background-color: #FAFAFA;
}

.event-box-item > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.event-box-item-sel {
  background-color: rgb(197, 219, 255);
  color: #1D73FF;
  border: 1px solid rgb(222, 224, 226)
}

.event-box-item {
  padding: 0 10px;
  height: 30px;
  width: 100%;
  line-height: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.pages-box2 {
}

#customToolbox {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  width: 200px;
  max-height: 600px;
  overflow-y: auto;
}

.toolbox-category {
  margin-bottom: 15px;
}

.toolbox-category h3 {
  font-size: 16px;
  margin: 0;
  padding: 5px 0;
}

.toolbox-block {
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
  text-align: center;
}

.toolbox-block:hover {
  background-color: #ccc;
}


/*#blocklyDiv > div >svg:nth-child(5){
  display: none !important;
}
#blocklyDiv > div{
  position: relative;
}
.blocklyToolboxDiv {
  position: fixed;
  top: 200px;
}
.blocklyScrollbarVertical{
  display: none !important;
}
.locklyScrollbarVertical{
  display: none;
}*/
.pages-box4 {
  width: 300px;
  box-shadow: rgb(222, 224, 226) -1px 0 0;
}

.pages-box3 {
  width: 130%;
}

.pages-box2 {
  width: 260px;
  padding-top: 20px;
  box-shadow: rgb(222, 224, 226) 1px 0 0;
}

.pages-box1 {
  box-shadow: rgb(222, 224, 226) 1px 0 0;
  width: 160px;
  padding: 20px 20px 20px 0;
}

.container-pages {
  display: flex;
  background: #fff;
  box-shadow: rgb(222, 224, 226) 0 -1px 0;
  height: 100%;
}

.blockly_header_right_item_helper {
  font-size: 18px;
}

.blockly_header_right_item > img {
  cursor: pointer;
}

.blockly_header_right_item {
  margin-right: 20px;
}

.blockly_header_right {
  display: flex;
  align-items: center;
}

.blockly_header_left {
  height: 48px;
  display: flex;
  align-items: center;
}

.blockly_header_left_title {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.blockly_header_left_title > div {
  display: flex;
  align-items: center;
}

.blockly_header_left_title i {
  margin: 0 0 0 20px;
}

.blockly_header {
  align-items: center;
  display: flex;
  height: 48px;
  padding: 0 20px;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  box-shadow: rgb(222, 224, 226) 0px 1px 0px;
}

.blockly_box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

view-text {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blockly-minimap {
  position: absolute;
  box-shadow: none;
  width: 200px;
  height: 200px;
  top: 3vw;
  left: 80vw;
}
</style>
<script>
import {mixin} from "./mixins";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "pages",
  mixins: [mixin],
}
</script>
