export const m = {
    test: "简体中文",
    language: "简体中文",
    cancel: "取消",
    ok: "确定",
}
export const home = {
    language: "语言",
    qq_login_btn: "登录",
    product_introduction: "产品介绍",
    quick_to_use: "快速使用",
    contact_us: "联系我们",
    help_documentation: "帮助文档",
    title: "Minecraft自定义逻辑低代码平台",
    sub_title: "强大的自定义选项，支持众多方法，通过低代码平台实现复杂逻辑，简化繁琐过程",
    banner: "/img/5DD046FE6DE6BCFA3433B823EF270491.c67392e8.png",
    app_creation: "应用创建",
    edit_the_module: "编辑模块",
    plugin_builds: "插件构建",
    operational_processes: "运转流程",
    enter_the_workbench: "进入工作台",
    free_to_use:"免费使用"
}
export const pages = {
    go_back: "返回至工作台",
    please_search_by_component_name: "请输入组件名称搜索",
    help: "帮助",
    save: "保存",
    search_triggers: "搜索触发器",
    tips: "提示",
    create_a_new_event_trigger: "新建事件触发器",
    type: "类型",
    player_events: "玩家事件",
    name: "名字",
    please_enter_a_first_name: "请输入名字",
    remark: "备注",
    please_enter: "请输入",
    cancel: "取消",
    ok: "确定",
    create_a_PAPI_placeholder: "新建PAPI占位符",
    it_can_be_obtained_by_identifying: "可通过 %ultrafunction_标识% 来获取",
    logo: "标识",
    please_enter_a_logo: "请输入标识",
    next_save: "您还未保存, 是否继续?",
    do_not_save: "不保存",
    gen_error: "代码生成失败,请联系管理员处理",
    error_player_msg: "错误的触发器，该方法只能用于 玩家聊天 触发器",
    error_player_command: "错误的触发器，该方法只能用于 玩家指令 触发器",
    error_player_server: "错误的触发器，该方法只能用于 服务器列表 触发器",
    error_player_move: "错误的触发器，该方法只能用于 玩家移动 触发器",
    error_player_lang: "错误的触发器，该方法只能用于 玩家改变语言设置 触发器",
    error_player_kill: "错误的触发器，该方法只能用于 玩家死亡 触发器",
    error_player_item: "错误的触发器，该方法只能用于 成功附魔物品 触发器",
    error_next_event: "您还未保存当前触发器, 是否继续?",
    select_event_type: "请选择触发器类型",
    input_name: "请输入名称",
    create_successfully: "创建成功",
    saved_successfully: "保存成功",
    delete_success: "删除成功",
    delete_event: `此操作将永久删除`,
    next_event: "触发器, 是否继续?"
}
export const workspace = {
    new: "新建",
    import: "导入",
    recent_files: "最近文件",
    my_favorites: "我的收藏",
    number_of_files: "文件数",
    upgrade: "立即升级",
    search_for_files: "搜索文件",
    download: "下载核心",
    file: "文件",
    recycle: "回收站",
    updated_on: "更新于",
    construct: "构建",
    remove: "删除",
    edit: "编辑",
    rename: "重命名",
    share: "分享",
    unfavorite: "取消收藏",
    favorites: "收藏",
    create_file: "新建文件",
    name: "名字",
    file_name: "请填写文件名",
    cancel: "取消",
    ok: "确定",
    share_files: "分享文件",
    share_code: "分享码",
    copy: "复制",
    copy_success: "复制成功",
    import_file: "导入文件",
    please_enter_the_sharing_code: "请输入分享码",
    creation_successful: "创建成功",
    file_not_empty: "文件名不能为空",
    function_not_yet_open: "功能暂未开放",
    please_enter_the_file_name: "请输入文件名",
    tips: "提示",
    save_delete: "此操作将永久删除该文件, 是否继续?",
    shared_ultraFunction: "我在 UltraFunction 向你分享了项目",
    shared_url: "请复制到平台查看！链接：https://ultrafunction.domcer.com/",
    The_sharing_code_is: "分享码为",
    the_replication_is_successful: "复制成功",
    the_import_was_successful: "导入成功"
}

export const event = {
    event_now_tooltip: "获取当前事件",
    event_now_message: "当前事件 %1",
    event_set_cancelled_tooltip: "取消事件",
    event_set_cancelled_message: "取消事件 事件： %1 布尔： %2",
    event_get_locale_tooltip: "获取选择的语言，切换语言的时候触发",
    event_get_locale_message: "获取选择的语言，切换语言的时候触发 %1",
    example_black_message: "创建空列表 %1",
}

export const base = {
    symbol_tooltip: "数量两个值输出布尔值",
    symbol_message: "值: %1 %2 %3 值: %4",
    number_tooltip: "创建一个数字",
    number_message: "数字： %1 %2",
    string_tooltip: "创建一个文本",
    string_message: "文本： %1",
    to_string_tooltip: "将一个数字转换为字符串",
    to_string_message: "转换字符串 %1",
    boolean_tooltip: "创建一个布尔类型",
    boolean_message: "布尔： %1 %2",
    concat_string_tooltip: "字符串拼接",
    concat_string_message: "字符串拼接 字符串1： %1 字符串2： %2",
    not_tooltip: "将一个布尔值取反",
    not_message: "逻辑取反 布尔： %1",
    generate_uuid_tooltip: "结果是一个字符串，不带 '-'",
    generate_uuid_message: "生成一串随机的UUID字符串 %1",
    sub_string_1_tooltip: "对应后端 str.subString(num)，从第几位开始截取后面的所有内容",
    sub_string_1_message: "截取字符串 字符串： %1 第几位开始： %2",
    contains_string_tooltip: "判断字符串A是否存在字符串B",
    contains_string_message: "判断字符串A是否存在字符串B 字符串A： %1 字符串B： %2",
    sub_string_2_tooltip: "对应后端 str.subString(num, num)，从第几位到第几位开始截取内容",
    sub_string_2_message: "截取字符串 字符串： %1 第几位开始： %2 第几位结束： %3",
    replace_string_tooltip: "将字符串A里面的字符串B内容替换为字符串C",
    replace_string_message: "替换字符串 原字符串： %1 被替换值： %2 替换值： %3",
    instance_of_tooltip: "判断数据类型是否为",
    instance_of_message: "判断数据类型是否为 任意数据： %1 数据类型： %2",
    struct_type_tooltip: "数据类型",
    struct_type_message: "数据类型： %1 %2",
    get_struct_type_tooltip: "获取数据类型",
    get_struct_type_message: "获取数据类型 任意数据： %1",
    base_is_null_tooltip: "可以传递任何一个内容，判断这个内容是否为空",
    base_is_null_message: "判断值是否为空 值： %1",
    base_operate_tooltip: "四则运算，可以对数值加减乘除",
    base_operate_message: "数字： %1 %2 %3 数字： %4",
    base_abs_tooltip: "求数值绝对值",
    base_abs_message: "求数值绝对值 值： %1",
    base_remainder_tooltip: "A除以B，余结果",
    base_remainder_message: "求余 值A： %1 值B： %2",
    base_random_number_tooltip: "求一个范围的随机数，从0开始，如果填写的内容为2，那么只会随机到0和1，结果为整数",
    base_random_number_message: "取一个范围内的随机数 范围： %1",
    base_and_or_tooltip: "与 或",
    base_and_or_message: "布尔： %1 %2 %3 布尔： %4",
    base_create_list_tooltip: "创建一个空List集合",
    base_create_list_message: "创建一个空List集合",
    base_list_add_tooltip: "往List集合中添加一个元素",
    base_list_add_message: "往List集合中添加一个元素 List集合： %1 值： %2",
    base_list_remove_element_tooltip: "从List集合中删除一个元素",
    base_list_remove_element_message: "从List集合中删除一个元素 List集合： %1 值： %2",
    base_list_remove_index_tooltip: "从List集合中删除第几个元素",
    base_list_remove_index_message: "从List集合中删除第几个元素 List集合： %1 第几个： %2",
    base_list_contains_tooltip: "判断List集合中是否存在某个元素",
    base_list_contains_message: "判断List集合中是否存在某个元素 List集合： %1 值： %2",
    base_list_get_tooltip: "从List集合中获取第几个元素",
    base_list_get_message: "从List集合中获取第几个元素 List集合： %1 第几个： %2",
    base_list_is_empty_tooltip: "判断List集合是否为空",
    base_list_is_empty_message: "判断List集合是否为空 List集合： %1",
    base_list_clear_tooltip: "将List集合清空",
    base_list_clear_message: "将List集合清空 List集合： %1",
    base_list_size_tooltip: "获取List集合的大小",
    base_list_size_message: "获取List集合的大小 List集合： %1",
    base_create_map_tooltip: "创建一个空Map",
    base_create_map_message: "创建一个空Map",
    base_map_put_num_tooltip: "往Map中添加一条数据(数字为键)",
    base_map_put_num_message: "往Map中添加一条数据(数字为键) Map： %1 键： %2 值： %3",
    base_map_put_str_tooltip: "往Map中添加一条数据(字符串为键)",
    base_map_put_str_message: "往Map中添加一条数据(字符串为键) Map： %1 键： %2 值： %3",
    base_map_remove_num_tooltip: "从Map中删除一条数据(数字为键)",
    base_map_remove_num_message: "从Map中删除一条数据(数字为键) Map： %1 键： %2",
    base_map_remove_str_tooltip: "从Map中删除一条数据(字符串为键)",
    base_map_remove_str_message: "从Map中删除一条数据(字符串为键) Map： %1 键： %2",
    base_map_get_num_tooltip: "从Map中获取一条数据(数字为键)",
    base_map_get_num_message: "从Map中获取一条数据(数字为键) Map： %1 键： %2",
    base_map_get_str_tooltip: "从Map中获取一条数据(字符串为键)",
    base_map_get_str_message: "从Map中获取一条数据(字符串为键) Map： %1 键： %2",
    base_map_contains_key_num_tooltip: "判断Map中是否存在某个键值(数字为键)",
    base_map_contains_key_num_message: "判断Map中是否存在某个键值(数字为键) Map： %1 键： %2",
    base_map_contains_key_str_tooltip: "判断Map中是否存在某个键值(字符串为键)",
    base_map_contains_key_str_message: "判断Map中是否存在某个键值(字符串为键) Map： %1 键： %2",
    base_map_clear_tooltip: "将Map清空",
    base_map_clear_message: "将Map清空 Map： %1",
    base_map_key_set_tooltip: "获取Map中所有键值集合",
    base_map_key_set_message: "获取Map中所有键值集合 Map： %1",
    base_map_values_tooltip: "获取Map中所有结果值集合",
    base_map_values_message: "获取Map中所有结果值集合 Map： %1",
    base_map_is_empty_tooltip: "判断Map是否为空",
    base_map_is_empty_message: "判断Map是否为空 Map： %1",
    base_map_size_tooltip: "获取Map的大小",
    base_map_size_message: "获取Map的大小 Map： %1",
    base_set_papi_result_tooltip: "设置PAPI占位符结果",
    base_set_papi_result_message: "设置PAPI占位符结果 值： %1",
    base_return_1_tooltip: "结束本次操作，方法不会继续往下执行",
    base_return_1_message: "结束本次操作",
    base_break_tooltip: "结束本次循环",
    base_break_message: "结束本次循环",
    base_continue_tooltip: "跳过本次循环",
    base_continue_message: "跳过本次循环",


    // Dropdown options
    options: {
        equal: "=",
        less_than: "<",
        greater_than: ">",
        less_than_or_equal: "<=",
        greater_than_or_equal: ">=",
        true: "是",
        false: "否",
        long: "整数",
        double: "小数",
        string: "字符",
        boolean: "布尔",
        list: "List",
        map: "Map",
        player: "玩家",
        location: "坐标",
        world: "世界",
        bossBar: "BossBar",
        scoreboard: "计分板",
        team: "队伍",
        add: "加",
        subtract: "减",
        multiply: "乘",
        divide: "除",
        and: "与",
        or: "或",
    }
};

export const block = {
    block_get_block_tooltip: "通过事件获取方块",
    block_get_block_message: "通过事件获取方块 事件： %1",
    block_set_type_tooltip: "设置方块类型",
    block_set_type_message: "设置方块类型 方块： %1 类型： %2",
    block_get_type_tooltip: "获取方块类型",
    block_get_type_message: "获取方块类型 方块： %1 ",
    block_get_block_location_tooltip: "通过坐标获取方块",
    block_get_block_location_message: "通过坐标获取方块 坐标： %1 ",
    block_break_naturally_tooltip: "破坏方块",
    block_break_naturally_message: "破坏方块 方块： %1 "
}

export const bossBar = {
    boss_bar_create_boss_bar_tooltip: "创建一条bossbar",
    boss_bar_create_boss_bar_message: "创建一条bossbar 标题： %1 颜色： %2 类型： %3 进度： %4",
    boss_bar_add_boss_bar_tooltip: "将某个bossbar展示给某玩家",
    boss_bar_add_boss_bar_message: "将某个bossbar展示给某玩家 玩家： %1 bossbar： %2",
    boss_bar_remove_boss_bar_tooltip: "将玩家的某个bossbar移除",
    boss_bar_remove_boss_bar_message: "将玩家的某个bossbar移除 玩家： %1 bossbar： %2",
    boss_bar_set_progress_message: "设置bossbar的进度条 bossbar： %1 范围 0 ~ 1.0  进度条： %2",
    boss_bar_set_visible_tooltip: "设置bossbar的可见性",
    boss_bar_set_visible_message: "设置bossbar的可见性 bossbar： %1 布尔： %2",
    boss_bar_set_color_tooltip: "设置bossbar颜色",
    boss_bar_set_color_message: "设置bossbar颜色 bossbar： %1 颜色： %2",
    boss_bar_set_style_tooltip: "设置bossbar类型",
    boss_bar_set_style_message: "设置bossbar类型 bossbar： %1 类型： %2",
    boss_bar_set_title_tooltip: "设置bossbar标题",
    boss_bar_set_title_message: "设置bossbar标题 bossbar： %1 标题： %2",
    boss_bar_set_remove_all_tooltip: "移除bossbar所有玩家",
    boss_bar_set_remove_all_message: "移除bossbar所有玩家 bossbar： %1"
}

export const data_source = {
    put_cache_tooltip: "可以放任意的value",
    put_cache_message: "将某个数据存放在全局变量缓存中 键： %1 值： %2",
    get_cache_tooltip: "返回的是个泛型",
    get_cache_message: "从全局变量缓存中拿一个数据 键： %1",
    remove_cache_tooltip: "",
    remove_cache_message: "从全局变量缓存中删除一个数据 键： %1",
    put_data_base_tooltip: "",
    put_data_base_message: "将某个数据存放在数据库中 键： %1 值： %2",
    get_data_base_tooltip: "返回的是个泛型",
    get_data_base_message: "从数据库中拿一个数据 键： %1",
    remove_data_base_tooltip: "",
    remove_data_base_message: "从数据库中删除一个数据 键： %1",
    contains_key_cache_tooltip: "数据是否存在于全局变量缓存中",
    contains_key_cache_message: "数据是否存在于全局变量缓存中 键： %1",
    contains_key_database_tooltip: "数据是否存在于数据库中",
    contains_key_database_message: "数据是否存在于数据库中 键： %1",
    get_string_from_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    get_string_from_config_message: "从配置文件中获取一个字符值 键： %1",
    get_long_from_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    get_long_from_config_message: "从配置文件中获取一个整数值 键： %1",
    get_double_from_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    get_double_from_config_message: "从配置文件中获取一个小数值 键： %1",
    get_boolean_from_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    get_boolean_from_config_message: "从配置文件中获取一个布尔值 键： %1",
    put_string_2_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    put_string_2_config_message: "往配置文件中存储一个字符值 键： %1 值： %2",
    put_long_2_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    put_long_2_config_message: "往配置文件中存储一个整数值 键： %1 值： %2",
    put_double_2_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    put_double_2_config_message: "往配置文件中存储一个小数值 键： %1 值： %2",
    put_boolean_2_config_tooltip: "键值为配置文件的路径，例如 A.B.C.D",
    put_boolean_2_config_message: "往配置存储一个布尔值 键： %1 值： %2",
    get_string_list_from_config_tooltip: "从配置文件中获取一个字符集合",
    get_string_list_from_config_message: "从配置文件中获取一个字符集合 键： %1",
    get_long_list_from_config_tooltip: "从配置文件中获取一个整数集合",
    get_long_list_from_config_message: "从配置文件中获取一个整数集合 键： %1",
    get_double_list_from_config_tooltip: "从配置文件中获取一个小数集合",
    get_double_list_from_config_message: "从配置文件中获取一个小数集合 键： %1",
};

export const field = {
    create_player_tooltip: "创建一个变量 来存储玩家对象",
    create_player_message: "创建玩家变量   变量名: %1 %2 获取方式： %3",
    get_player_tooltip: "从变量中取到玩家对象",
    get_player_message: "获取玩家变量   变量名: %1 %2",
    create_string_tooltip: "字符串变量",
    create_string_message: "创建字符串变量   变量名: %1 %2 获取方式 %3",
    get_string_tooltip: "获取字符串变量",
    get_string_message: "获取字符串变量 %1 %2",
    create_number_tooltip: "创建数字变量 ",
    create_number_message: "创建数字变量  变量名： %1 %2 获取方式： %3",
    get_number_tooltip: "获取数字变量",
    get_number_message: "获取数字变量 变量名： %1 %2",
    create_boolean_tooltip: "创建布尔变量",
    create_boolean_message: "创建布尔变量 变量名 %1 %2 获取方式 %3",
    get_boolean_tooltip: "获取布尔变量",
    get_boolean_message: "获取布尔变量 变量名： %1 %2",
    create_location_tooltip: "创建坐标变量",
    create_location_message: "创建坐标变量 变量名： %1 %2 获取方式: %3",
    get_location_tooltip: "获取坐标变量",
    get_location_message: "获取坐标变量 变量名： %1 %2",
    create_world_tooltip: "创建世界变量",
    create_world_message: "创建世界变量 变量名： %1 %2 获取方式： %3",
    get_world_tooltip: "获取世界变量",
    get_world_message: "获取世界变量 变量名： %1 %2",
    create_boss_bar_tooltip: "创建bossbar变量",
    create_boss_bar_message: "创建bossbar变量 变量名： %1 %2 获取方式： %3",
    get_boss_bar_tooltip: "获取bossbar变量",
    get_boss_bar_message: "获取bossbar变量 变量名： %1 %2",
    create_scoreboard_tooltip: "创建计分板变量",
    create_scoreboard_message: "创建计分板变量 变量名： %1 %2 获取方式： %3",
    get_scoreboard_tooltip: "获取计分板变量",
    get_scoreboard_message: "获取计分板变量 变量名： %1 %2",
    create_list_tooltip: "创建list集合变量",
    create_list_message: "创建list集合变量 变量名： %1 %2 获取方式： %3",
    get_list_tooltip: "获取list集合变量",
    get_list_message: "获取list集合变量 变量名： %1 %2",
    create_map_tooltip: "创建map变量",
    create_map_message: "创建map变量 变量名： %1 %2 获取方式： %3",
    get_map_tooltip: "获取map变量",
    get_map_message: "获取map变量 变量名： %1 %2",
    create_team_tooltip: "创建队伍变量",
    create_team_message: "创建队伍变量 变量名： %1 %2 获取方式： %3",
    get_team_tooltip: "获取队伍变量",
    get_team_message: "获取队伍变量 变量名： %1 %2",
    create_block_tooltip: "创建方块变量",
    create_block_message: "创建方块变量 变量名： %1 %2 获取方式： %3",
    get_block_tooltip: "获取方块变量",
    get_block_message: "获取方块变量 变量名： %1 %2"
};

export const translations = {
    function_block: {
        tooltip: "这是一个方法，在里面拖入事件方块",
        message0: "方法名 %1 %2 描述 %3 %4 是否启用 %5 %6 %7"
    },
    trigger_block: {
        tooltip: "当前触发器",
        message0: "当前触发器 %1 请将你的逻辑放入该积木内 %2 帮助页面：https://ultrafunction.domcer.com/wiki %3 %4"
    },
    trigger_block_papi: {
        tooltip: "PAPI占位符",
        message0: "PAPI占位符 %1 可通过 %ultrafunction_标识% 来获取 %2 请将你的逻辑放入该积木内 %3 帮助页面：https://ultrafunction.domcer.com/wiki %4 %5 %6 返回结果为：%7"
    }
};

export const location = {
    create_1_tooltip: "创建坐标",
    create_1_message0: "创建坐标 X： %1 Y： %2 Z： %3",
    create_2_tooltip: "创建坐标",
    create_2_message0: "创建坐标 X： %1 Y： %2 Z： %3 左右： %4 上下： %5",
    create_3_tooltip: "创建坐标",
    create_3_message0: "创建坐标 世界： %1 X： %2 Y： %3 Z： %4",
    create_4_tooltip: "创建坐标",
    create_4_message0: "创建坐标 世界： %1 X： %2 Y： %3 Z： %4 左右： %5 上下： %6",
    get_x_tooltip: "获取X轴",
    get_x_message0: "获取X轴 坐标： %1",
    get_y_tooltip: "获取Y轴",
    get_y_message0: "获取Y轴 坐标： %1",
    get_z_tooltip: "获取Z轴",
    get_z_message0: "获取Z轴 坐标： %1",
    get_yaw_tooltip: "获取水平视角",
    get_yaw_message0: "获取水平视角 坐标： %1",
    get_pitch_tooltip: "获取垂直视角",
    get_pitch_message0: "获取垂直视角 坐标： %1",
    get_to_tooltip: "获取移动终点只能是 玩家移动 触发器",
    get_to_message0: "获取移动终点 %1",
    get_from_tooltip: "获取移动起点只能是 玩家移动 触发器",
    get_from_message0: "获取移动起点 %1",
    get_block_tooltip: "获取方块坐标",
    get_block_message0: "获取方块坐标 方块：%1"
};

export const logical_control = {
    if_tooltip: "判断控制语句",
    if_message0: "如果 %1 那么 %2",
    if_else_tooltip: "",
    if_else_message0: "如果 %1 那么 %2 否则 %3",
    repeat_do_tooltip: "重复多少次做一件事情",
    repeat_do_message0: "重复 次数： %1 做什么事情 %2 %3",
    while_do_tooltip: "当满足条件后重复做一件事情",
    while_do_message0: "当满足条件 布尔： %1 一直做什么事情 %2 %3",
    timer_1_tooltip: "延迟多少tick执行 什么事情",
    timer_1_message0: "一次性定时器 延迟Tick: %1 做什么 %2 %3",
    timer_2_tooltip: "延迟多少tick执行并且间隔多少tick执行 什么事情",
    timer_2_message0: "重复性定时器 延迟Tick: %1 间隔多少Tick执行: %2 做什么 %3 %4",
};

export const message = {
    player_send_message_tooltip: "给一个玩家发送消息",
    player_send_message_message0: "给 %1 发送一条 %2 消息 %3",
    player_send_message_all_tooltip: "",
    player_send_message_all_message0: "给 全体玩家 %1 发送一条 %2 消息 %3",
    set_join_message_tooltip: "设置加入服务器消息",
    set_join_message_message0: "设置加入服务器消息 %1",
    set_quit_message_tooltip: "设置退出服务器消息",
    set_quit_message_message0: "设置退出服务器消息 %1",
    set_chat_format_tooltip: "仅玩家聊天事件可用",
    set_chat_format_message0: "设置聊天格式 %1",
    get_chat_message_tooltip: "获取聊天内容",
    get_chat_message_message0: "获取聊天内容 %1",
    get_command_tooltip: "获取指令内容",
    get_command_message0: "获取指令内容 %1",
    get_papi_result_tooltip: "papi占位符，例如 %server_tps%，如果是和玩家变量相关的占位符，需要使用下面的方法",
    get_papi_result_message0: "获得papi变量结果 papi占位符： %1",
    get_papi_result_player_tooltip: "papi占位符，例如 %player_name%",
    get_papi_result_player_message0: "获得papi变量结果 papi占位符： %1 玩家： %2"
};

export const scoreboard = {
    create_tooltip: "行 是个字符串json，例如 {\"123\",\"123\"}",
    create_message0: "创建一个计分板 标题： %1 行： %2",
    create_list_tooltip: "创建一个计分板",
    create_list_message0: "创建一个计分板 标题： %1 List集合： %2",
    set_scoreboard_tooltip: "",
    set_scoreboard_message0: "给玩家设定一个计分板 玩家： %1 计分板： %2",
    reset_scoreboard_tooltip: "",
    reset_scoreboard_message0: "重置一个玩家的计分板 玩家： %1"
};

export const server = {
    set_motd_tooltip: "设置服务器MOTD 只能是 服务器列表 事件",
    set_motd_message0: "设置服务器MOTD 事件： %1 第一行 %2 第二行 %3",
    dispatch_command_tooltip: "",
    dispatch_command_message0: "让控制台强制执行一个指令 命令： %1",
    get_online_players_size_tooltip: "获取当前在线玩家数量",
    get_online_players_size_message0: "获取当前在线玩家数量"
};

export const team = {
    create_team_tooltip: "在服务器主要计分板中创建队伍",
    create_team_message0: "在服务器主要计分板中创建队伍 队伍名字： %1",
    create_team_scoreboard_tooltip: "在指定的计分板中创建队伍",
    create_team_scoreboard_message0: "在指定的计分板中创建队伍 计分板： %1 队伍名字： %2",
    get_team_tooltip: "在服务器主要计分板中获取队伍",
    get_team_message0: "在服务器主要计分板中获取队伍 队伍名字： %1",
    get_team_scoreboard_tooltip: "在指定的计分板中获取队伍",
    get_team_scoreboard_message0: "在指定的计分板中获取队伍 计分板： %1 队伍名字： %2",
    get_player_team_tooltip: "在服务器主要计分板中获取玩家的队伍",
    get_player_team_message0: "在服务器主要计分板中获取玩家的队伍 玩家： %1",
    get_player_team_scoreboard_tooltip: "在指定的计分板中获取玩家的队伍",
    get_player_team_scoreboard_message0: "在指定的计分板中获取玩家的队伍 计分板： %1 玩家： %2",
    add_player_2_team_tooltip: "将玩家添加到主要计分板中某个队伍中",
    add_player_2_team_message0: "将玩家添加到主要计分板中某个队伍中 玩家： %1 队伍名字： %2",
    add_player_2_team_team_tooltip: "将玩家添加到某个队伍中",
    add_player_2_team_team_message0: "将玩家添加到某个队伍中 玩家： %1 队伍实例： %2",
    is_player_in_team_tooltip: "判断玩家是否在主要计分板中某个队伍中",
    is_player_in_team_message0: "判断玩家是否在主要计分板中某个队伍中 玩家： %1 队伍名字： %2",
    is_player_in_team_team_tooltip: "判断玩家是否在某个队伍中",
    is_player_in_team_team_message0: "判断玩家是否在某个队伍中 玩家： %1 队伍实例： %2",
    remove_player_4_team_tooltip: "将玩家从主要计分板中某个队伍中移除",
    remove_player_4_team_message0: "将玩家从主要计分板中某个队伍中移除 玩家： %1 队伍名字： %2",
    remove_player_4_team_team_tooltip: "将玩家从某个队伍中移除",
    remove_player_4_team_team_message0: "将玩家从某个队伍中移除 玩家： %1 队伍实例： %2",
    delete_team_tooltip: "将服务器主要计分板中某个队伍删除",
    delete_team_message0: "将服务器主要计分板中某个队伍删除 队伍名字： %1",
    delete_team_team_tooltip: "将某个队伍删除",
    delete_team_team_message0: "将某个队伍删除 队伍实例： %1",
    get_color_tooltip: "获取队伍的颜色",
    get_color_message0: "获取队伍的颜色 队伍实例： %1",
    get_entries_tooltip: "获取队伍中的所有内容，队伍中可以不光存储Player，也可以存储其他内容",
    get_entries_message0: "获取队伍中所有的内容 队伍实例： %1",
    get_display_name_tooltip: "获取队伍的显示名",
    get_display_name_message0: "获取队伍的显示名 队伍实例： %1",
    get_name_tag_visibility_tooltip: "获取队伍头顶Tag可视性的类型",
    get_name_tag_visibility_message0: "获取队伍头顶Tag可视性的类型 队伍实例： %1",
    get_name_tooltip: "获取队伍的名字",
    get_name_message0: "获取队伍的名字 队伍实例： %1",
    get_player_tooltip: "获取队伍中所有的玩家",
    get_player_message0: "获取队伍中所有的玩家 队伍实例： %1",
    get_prefix_tooltip: "获取队伍的前缀",
    get_prefix_message0: "获取队伍的前缀 队伍实例： %1",
    get_suffix_tooltip: "获取队伍的后缀",
    get_suffix_message0: "获取队伍的后缀 队伍实例： %1",
    get_scoreboard_tooltip: "获取队伍的计分板",
    get_scoreboard_message0: "获取队伍的计分板 队伍实例： %1",
    get_size_tooltip: "获取队伍的大小",
    get_size_message0: "获取队伍的大小 队伍实例： %1",
    set_allow_friendly_fire_tooltip: "设置队伍中的成员能否互相攻击",
    set_allow_friendly_fire_message0: "设置队伍中的成员能否互相攻击 队伍实例： %1 布尔： %2",
    set_can_see_friendly_invisible_tooltip: "设置队伍中成员能否看见处于隐身状态效果下的队友",
    set_can_see_friendly_invisible_message0: "设置队伍中成员能否看见处于隐身状态效果下的队友 队伍实例： %1 布尔： %2",
    set_color_tooltip: "设置队伍的颜色",
    set_color_message0: "设置队伍的颜色 队伍实例： %1 值： %2",
    set_display_name_tooltip: "设置队伍的显示名",
    set_display_name_message0: "设置队伍的显示名 队伍实例： %1 值： %2",
    set_name_tag_visibility_tooltip: "设置队伍头顶Tag可视性的类型",
    set_name_tag_visibility_message0: "设置队伍头顶Tag可视性的类型 队伍实例： %1 值： %2",
    set_prefix_tooltip: "设置队伍的前缀",
    set_prefix_message0: "设置队伍的前缀 队伍实例： %1 值： %2",
    set_suffix_tooltip: "设置队伍的后缀",
    set_suffix_message0: "设置队伍的后缀 队伍实例： %1 值： %2"

};

export const world = {
    player_get_world_tooltip: "获取玩家所在的世界",
    player_get_world_message0: "获取玩家所在的世界 玩家： %1",
    get_world_tooltip: "获取世界实例",
    get_world_message0: "获取世界实例 世界名： %1",
    spawn_experience_tooltip: "生成经验球 需要大于等于1，否则不会生成经验",
    spawn_experience_message0: "生成经验球 坐标： %1 经验数： %2",
    spawn_firework_tooltip: "根据坐标，生成一个最简单的烟花，未来会拓展烟花自定义设置",
    spawn_firework_message0: "生成烟花 坐标： %1"
};

export const player = {
    get_event_tooltip: "通过事件获取一个玩家实例",
    get_event_message0: "获取玩家实例 事件： %1",
    get_world_tooltip: "获取玩家所在的世界",
    get_world_message0: "获取玩家所在的世界 玩家： %1",
    get_player_tooltip: "通过玩家名字获取玩家实例",
    get_player_message0: "通过玩家名字获取玩家实例 玩家名： %1",
    get_enchanter_tooltip: "通过附魔事件获取玩家实例 只能是 成功附魔物品 触发器",
    get_enchanter_message0: "通过附魔事件获取玩家实例 事件： %1",
    get_health_tooltip: "获取玩家血量",
    get_health_message0: "获取玩家血量  玩家： %1",
    set_health_tooltip: "设置一个玩家的血量",
    set_health_message0: "设置玩家血量 玩家： %1 血量： %2",
    get_name_tooltip: "通过玩家实例获取玩家名字",
    get_name_message0: "获取玩家名字 玩家： %1",
    has_permission_tooltip: "检查是否拥有权限",
    has_permission_message0: "检查是否拥有权限 玩家： %1 权限： %2",
    set_fly_tooltip: "设置玩家飞行状态",
    set_fly_message0: "设置飞行 玩家： %1 状态： %2",
    send_title_tooltip: "给玩家发送一个标题",
    send_title_message0: "给 玩家： %1 发送一条标题 %2 主标题： %3 %4 副标题： %5 %6 下方时间以 Tick 为单位，20 Tick 为 1 秒 %7 标题淡入时间： %8 Tick %9 %10 标题停留/展示时长： %11 Tick %12 %13 标题淡出时间： %14 Tick %15",
    reset_title_tooltip: "重置标题",
    reset_title_message0: "重置标题 玩家： %1",
    get_location_tooltip: "通过玩家获取坐标",
    get_location_message0: "获取玩家世界坐标 玩家： %1",
    get_unique_id_tooltip: "获取玩家UUID",
    get_unique_id_message0: "获取玩家UUID 玩家： %1",
    set_respawn_location_tooltip: "设置玩家重生点",
    set_respawn_location_message0: "设置玩家重生点 玩家： %1 坐标： %2",
    get_max_health_tooltip: "获取玩家最大血量",
    get_max_health_message0: "获取玩家最大血量 玩家： %1",
    get_level_tooltip: "获取玩家等级",
    get_level_message0: "获取玩家等级 玩家： %1",
    set_level_tooltip: "设置玩家等级",
    set_level_message0: "设置玩家等级 玩家： %1 等级：%2",
    get_fly_speed_tooltip: "获取玩家飞行速度",
    get_fly_speed_message0: "获取玩家飞行速度 玩家： %1",
    set_fly_speed_tooltip: "设置玩家飞行速度",
    set_fly_speed_message0: "设置玩家飞行速度 玩家： %1 速度： %2",
    get_ping_tooltip: "获取玩家的延迟",
    get_ping_message0: "获取玩家的延迟 玩家： %1",
    get_food_level_tooltip: "获取玩家的饱食度",
    get_food_level_message0: "获取玩家的饱食度 玩家： %1",
    set_food_level_tooltip: "设置玩家的饱食度",
    set_food_level_message0: "设置玩家的饱食度 玩家： %1 饱食度： %2",
    get_allow_flight_tooltip: "获取玩家是否允许飞行",
    get_allow_flight_message0: "获取玩家是否允许飞行 玩家： %1",
    get_bed_location_tooltip: "获取玩家床的位置",
    get_bed_location_message0: "获取玩家床的位置 玩家： %1 %2 如果玩家床不在，结果是 null %3",
    get_attack_cooldown_tooltip: "获取玩家攻击冷却 (游戏版本1.9+)",
    get_attack_cooldown_message0: "获取玩家攻击冷却 (游戏版本1.9+) 玩家： %1",
    check_is_op_tooltip: "检查是否是OP",
    check_is_op_message0: "检查是否是OP 玩家： %1",
    teleport_tooltip: "将玩家传送至一个坐标",
    teleport_message0: "将玩家传送至一个坐标 玩家： %1 坐标： %2",
    set_max_health_tooltip: "设置最大血量",
    set_max_health_message0: "设置最大血量 玩家： %1 血量： %2",
    get_list_name_tooltip: "获取Tab列表中名字",
    get_list_name_message0: "获取Tab列表中名字 玩家： %1",
    set_list_name_tooltip: "设置Tab列表中名字",
    set_list_name_message0: "设置Tab列表中名字 玩家： %1 名字： %2",
    set_weather_tooltip: "设置玩家自己的天气，可以和当前世界天气不同",
    set_weather_message0: "设置天气 玩家： %1 天气： %2",
    reset_weather_tooltip: "重置玩家自己的天气，和当前世界天气保持一致",
    reset_weather_message0: "重置天气 玩家： %1",
    set_time_tooltip: "1秒20Tick",
    set_time_message0: "设置时间 玩家： %1 Tick： %2 %3",
    set_time2_tooltip: "假如相对时间为真则玩家时间会随着世界时间变动而变动,并且保持一个差值,反之客户端时间固定不动. 注意!如果相对时间为真,那么time表示的就是客户端的时间与世界的时间之差(相对于世界的时间),而如果为假那么time表示的就是一个绝对的时间.",
    set_time2_message0: "设置时间 玩家： %1 Tick： %2 %3 是否相对时间： %4",
    reset_time_tooltip: "将玩家时间变为当前世界时间并与世界时间保持同步",
    reset_time_message0: "重置时间 玩家： %1",
    get_walk_speed_tooltip: "获取行走速度",
    get_walk_speed_message0: "获取行走速度 玩家： %1",
    set_walk_speed_tooltip: "速度在-1~1区间",
    set_walk_speed_message0: "设置行走速度 玩家： %1 速度： %2 %3",
    set_can_pickup_items_tooltip: "设置是否允许捡物品",
    set_can_pickup_items_message0: "设置是否允许捡物品 玩家： %1 是否允许： %2",
    close_inventory_tooltip: "关闭背包窗口",
    close_inventory_message0: "关闭背包窗口 玩家： %1",
    spawn_particle_tooltip: "生成粒子",
    spawn_particle_message0: "生成粒子 玩家： %1 粒子效果 %2 X： %3 Y： %4 Z： %5 数量： %6",
    spawn_particle_location_tooltip: "生成粒子",
    spawn_particle_location_message0: "生成粒子 玩家： %1 粒子效果： %2 坐标： %3 数量： %4",
    set_list_header_tooltip: "设置TAB列表上方内容",
    set_list_header_message0: "设置TAB列表上方内容 玩家： %1 内容： %2",
    set_list_footer_tooltip: "设置TAB列表下方内容",
    set_list_footer_message0: "设置TAB列表下方内容 玩家： %1 内容： %2",
    set_list_header_footer_tooltip: "设置TAB列表上方和下方内容",
    set_list_header_footer_message0: "设置TAB列表上方和下方内容 玩家： %1 上方内容： %2 下方内容： %3",
    kick_tooltip: "踢出玩家",
    kick_message0: "踢出 玩家： %1 原因： %2",
    sudo_chat_tooltip: "强制玩家发送一个聊天消息,或强制使用命令(需要在内容前加 \"/\")",
    sudo_chat_message0: "强制某个玩家发送一个消息或命令 玩家： %1 内容： %2",
    set_absorption_amount_tooltip: "",
    set_absorption_amount_message0: "设置吸收伤害 玩家： %1 值： %2",
    set_invisible_tooltip: "设置可见性",
    set_invisible_message0: "设置可见性 玩家： %1 是否可见： %2",
    is_invisible_tooltip: "检查是否可见",
    is_invisible_message0: "检查是否可见 玩家： %1",
    is_in_water_tooltip: "检查玩家是否在水中",
    is_in_water_message0: "检查是否在水中 玩家： %1",
    send_sound_tooltip: "播放声音",
    send_sound_message0: "播放声音 玩家： %1 声音： %2 声音大小： %3 音调： %4",
    get_game_mode_tooltip: "获取玩家游戏模式",
    get_game_mode_message0: "获取玩家游戏模式 玩家： %1",
    set_game_mode_tooltip: "设置玩家游戏模式",
    set_game_mode_message0: "设置玩家游戏模式 玩家： %1 游戏模式： %2",
    potion_effect_tooltip: "设置玩家添加药水效果",
    potion_effect_message0: "设置玩家添加药水效果 玩家： %1 药水效果： %2 持续时间： %3 等级： %4",
    set_glowing_tooltip: "是否高亮",
    set_glowing_message0: "设置高亮玩家 玩家： %1 布尔： %2",
    give_exp_tooltip: "给予玩家经验",
    give_exp_message0: "给予玩家经验 玩家： %1 经验： %2",
    give_exp_levels_tooltip: "给予玩家等级",
    give_exp_levels_message0: "给予玩家等级 玩家： %1 等级： %2",
    set_compass_target_tooltip: "设置玩家指南针所指向的目标",
    set_compass_target_message0: "设置玩家指南针所指向的目标 玩家： %1 坐标： %2",
    hide_player_tooltip: "playerA, playerB，A玩家视角将不会显示B玩家",
    hide_player_message0: "设置对A玩家隐藏B玩家 玩家A： %1 玩家B： %2",
    show_player_tooltip: "playerA, playerB，A玩家视角将会显示B玩家",
    show_player_message0: "设置对A玩家显示B玩家 玩家A： %1 玩家B： %2",
    set_sleep_ignored_tooltip: "设置为 \"是\" 时，该名玩家不需要睡觉，其他玩家睡觉即可跳过夜晚",
    set_sleep_ignored_message0: "设置睡觉时是否忽略该玩家 玩家： %1 布尔： %2",
    is_sleeping_ignored_tooltip: "获取睡觉时是否忽略该玩家",
    is_sleeping_ignored_message0: "获取睡觉时是否忽略该玩家 玩家： %1",
    is_sleeping_tooltip: "获取玩家是否处于睡觉状态",
    is_sleeping_message0: "获取玩家是否处于睡觉状态 玩家： %1",
    set_total_experience_tooltip: "设置玩家当前总经验",
    set_total_experience_message0: "设置玩家当前总经验 玩家： %1 经验： %2",
    get_total_experience_tooltip: "获得玩家总经验",
    get_total_experience_message0: "获得玩家总经验 玩家： %1",
    add_item_tooltip: "给予玩家物品",
    add_item_message0: "给予玩家物品 玩家： %1 物品： %2 个数： %3",
    set_player_glow_4_receiver_tooltip: "玩家A发光，只有B玩家可以看见，需要 ProtocolLib 支持",
    set_player_glow_4_receiver_message0: "让A玩家发光且仅对B玩家生效 玩家A： %1 玩家B： %2",
    remove_player_glow_4_receiver_tooltip: "玩家A取消发光，只有B玩家可以看见，需要 ProtocolLib 支持",
    remove_player_glow_4_receiver_message0: "让A玩家取消发光且仅对B玩家生效 玩家A： %1 玩家B： %2",
    set_velocity_tooltip: "将会给玩家一个作用力，可做到类似踏板跳跃的操作",
    set_velocity_message0: "设置玩家矢量 玩家： %1 X： %2 Y： %3 Z： %4",
    stp_tooltip: "玩家，子服务器名称，根据 BungeeCord 配置",
    stp_message0: "将玩家传送到子服务器 玩家： %1 子服名： %2",
    give_balance_tooltip: "给予玩家金钱",
    give_balance_message0: "给予玩家金钱 玩家： %1 数量： %2",
    take_balance_tooltip: "拿走玩家金钱",
    take_balance_message0: "拿走玩家金钱 玩家： %1 数量： %2",
    get_balance_tooltip: "获取玩家金钱数量",
    get_balance_message0: "获取玩家金钱数量 玩家： %1",
    has_balance_tooltip: "玩家是否拥有相对数量的金钱",
    has_balance_message0: "玩家是否拥有相对数量的金钱 玩家： %1 数量： %2",
    get_killer_tooltip: "获取击杀这个玩家的人",
    get_killer_message0: "获取击杀这个玩家的人 玩家： %1",
    set_actionbar_tooltip: "ActionBar 是临时消息，如果想要持久性显示，需要自己额外开启定时器循环调用，ActionBar 临时消息预计会展示2~3秒",
    set_actionbar_message0: "设置玩家的 ActionBar 玩家： %1 内容： %2",
    get_item_in_hand_tooltip: "获取玩家当前手中的物品",
    get_item_in_hand_message0: "获取玩家当前手中的物品 玩家： %1",
    get_display_name_tooltip: "获取玩家显示名",
    get_display_name_message0: "获取玩家显示名 玩家： %1",
    set_display_name_tooltip: "设置玩家显示名",
    set_display_name_message0: "设置玩家显示名 玩家： %1 内容： %2",
    get_papi_player_tooltip: "获取触发该PAPI占位符的玩家",
    get_papi_player_message0: "获取触发该PAPI占位符的玩家"
};
