import * as hans from 'blockly/msg/zh-hans'
import * as Blockly from 'blockly/core';
import {javascriptGenerator} from 'blockly/javascript';
import {index_block} from "@/data/customize/index_block";
import {index_category} from "@/data/customize/index_category";
import "/src/data/customize/index_generator"
import {DisableTopBlocks} from "@blockly/disable-top-blocks";
import {FixedEdgesMetricsManager} from "@blockly/fixed-edges";
import {
    createTriggerApi,
    deleteTriggerApi,
    getTriggerComponentListApi,
    getUserTriggerListApi,
    saveAndUpdateTriggerApi
} from "@/api/file";
import gzipUtil from "@/utils/gzipUtil";
import {createPlusField} from "@/utils/field_plus";
import {createMinusField} from "@/utils/field_minus";
import '@/utils/if';
import {CrossTabCopyPaste} from "@blockly/plugin-cross-tab-copy-paste";
import {ZoomToFitControl} from "@blockly/zoom-to-fit";

export const mixin = {
    data() {
        return {
            createEvent: false,
            createPAPI: false,
            checkChange: false,
            workspace: null,
            generatedCode: '',
            saveCodeJson: {},
            originalToolbox: [...index_category],
            baseEvent: "",
            fileUuid: "",
            fileName: "",
            input: "",
            isShow: true,
            searchTriggerName: "",
            userTriggerList: [],
            triggerComponentList: [],
            trigger: {
                fileUuid: "",
                type: "",
                name: "",
                description: "",
                triggerType: ""
            },
            triggerSelIndex: "",
            triggerItem: {
                triggerUuid: "",
                description: "",
                enable: "",
                schema: "",
                expression: "",
            },
            userInfo: "",
            selSpace: 0,
            triggerTypeList: [
                {
                    name: "事件",
                    nameCreate: "新建事件触发器",
                    type: "EVENT",
                    icon: "el-icon-s-promotion",
                    css: "font-size: 16px"
                }, {
                    name: "PAPI",
                    nameCreate: "新建PAPI占位符",
                    type: "PAPI",
                    icon: "el-icon-s-promotion",
                    css: "font-size: 14px"
                }
                // , {
                //     name: "背包",
                //     nameCreate: "新建背包",
                //     type: "BACKPACK",
                //     icon: "el-icon-s-promotion",
                //     css: "font-size: 14px"
                // },
                // {
                //     name: "公共方法",
                //     nameCreate: "新建公共方法",
                //     type: "COMMON_METHOD",
                //     icon: "el-icon-s-promotion",
                //     css: "font-size: 12px"
                // }
            ]
        };
    },
    mounted() {
        let {
            fileUuid,
            fileName
        } = this.$route.query
        if (!fileUuid && !fileName) {
            this.$router.push("/workspace")
        }
        this.fileUuid = fileUuid
        this.fileName = fileName
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
        this.getUserTriggerList()

        // 初始化跨画布复制插件  只需要初始化一次
        const plugin = new CrossTabCopyPaste();
        plugin.init({
            contextMenu: true,
            shortcut: true,
        }, () => {
            console.log('Use this error callback to handle TypeError while pasting');
        });

    },
    methods: {
        switchSpace(index) {
            if (this.triggerSelIndex !== "" && this.checkChange) {
                this.$confirm(this.$t('pages.next_save'), this.$t('pages.tips'), {
                    confirmButtonText: this.$t('pages.save'),
                    cancelButtonText: this.$t('pages.do_not_save'),
                    type: 'warning'
                }).then(() => {
                    this.saveToServe()
                    this.resetWorkspace()
                }).catch(() => {
                    this.resetWorkspace()
                });
            }
            this.resetItem()
            this.selSpace = index
            this.triggerSelIndex = ""
            this.checkChange = false
            this.userTriggerList = []
            this.getUserTriggerList()
        },
        goHome() {
            this.$router.push("/")
        },
        goBack() {
            this.$router.back()
        },
        createElement() {
            console.log(this.$refs.blocklyDiv)
        },
        searchToolbox(event) {
            const searchTerm = event.toLowerCase();

            // 过滤工具箱内容
            const filteredToolbox = this.originalToolbox.map(category => {
                const filteredContents = category.contents.filter(block => block.name.toLowerCase().includes(searchTerm)
                );
                return {...category, contents: filteredContents};
            }).filter(category => category.contents.length > 0);
            // 更新工具箱
            this.workspace.updateToolbox({
                kind: "categoryToolbox",
                contents: filteredToolbox
            });
        },
        initializeBlockly() {
            //使用中文包
            Blockly.setLocale(hans);
            //定义组件
            Blockly.defineBlocksWithJsonArray(index_block);
            FixedEdgesMetricsManager.setFixedEdges({
                top: true,
                left: true,
            });


            /*Blockly.Trashcan.prototype.createDom = function() {
                this.svgGroup_ = Blockly.utils.dom.createSvgElement('g', {'class': 'blocklyTrash'}, null);

                // 创建垃圾桶的自定义图标
                this.svgImg_ = Blockly.utils.dom.createSvgElement('image', {
                    'width': 148,  // 自定义图标的宽度
                    'height': 148, // 自定义图标的高度
                    'href': 'https://webapi.domcer.com/img/DoMCer-logotxt.png'  // 本地图片路径
                }, this.svgGroup_);
                const xPosition = 20;
                const yPosition = 800;
                this.svgGroup_.setAttribute('transform', `translate(${xPosition}, ${yPosition})`);
                return this.svgGroup_;
            };
*/

            this.workspace = Blockly.inject('blocklyDiv', {
                toolboxPosition: 'end',
                toolbox: {
                    kind: "categoryToolbox",
                    contents: [...index_category]
                },
                grid: {
                    spacing: 20,
                    length: 3,
                    colour: '#ccc',
                    snap: true
                },
                move: {
                    scrollbars: {
                        horizontal: true,
                        vertical: true
                    },
                    drag: true,
                    wheel: true
                },
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 1.0,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2
                },
                disable: true,
                trashcan: true,
                plugins: {
                    metricsManager: FixedEdgesMetricsManager,
                },
            });
            // 画布放大到适合的位置
            const zoomToFit = new ZoomToFitControl(this.workspace);
            zoomToFit.init();

            // 迷你地图插件 有BUG  切换后不刷新画布
            // const minimap = new Minimap(this.workspace);
            // minimap.init();


            this.loadCode()
            this.onChange()


        },
        createBlock(type) {
            // 创建一个新的块实例
            const block = this.workspace.newBlock(type);
            block.initSvg();
            block.render();

            // 设置块的位置到鼠标处（例如在工作区的左上角），根据需要调整
            block.moveBy(100, 50);
        },
        generateCode() {
            let command = ""
            try {
                command = javascriptGenerator.workspaceToCode(this.workspace);
            } catch (e) {
                this.$message.error(this.$t('pages.gen_error')+"\n" + e)
                return
            }
            this.triggerItem.expression = gzipUtil.compressNoJsonToBase64(command);
        },
        saveCode() {
            this.saveCodeJson = JSON.parse(JSON.stringify(Blockly.serialization.workspaces.save(Blockly.getMainWorkspace())))
            this.triggerItem.schema = gzipUtil.compressToBase64(this.saveCodeJson)
        },
        loadCode() {
            Blockly.serialization.workspaces.load(this.saveCodeJson, this.workspace)
        },
        onChange() {
            // 添加未连接积木禁用插件
            this.workspace.addChangeListener(Blockly.Events.disableOrphans);
            const disableTopBlocksPlugin = new DisableTopBlocks();
            disableTopBlocksPlugin.init();

            // 为积木注册 onchange 事件
            Blockly.getMainWorkspace().addChangeListener((event) => {
                let eventWorkspace = event.getEventWorkspace_();
                let allBlocks = eventWorkspace.getAllBlocks();
                if (event.type === "drag") {
                    this.checkChange = true;
                }

                // 校验根块只能有一个
                const triggerBlocks = allBlocks.filter(block => block.type === 'TRIGGER_BLOCK' || block.type === 'TRIGGER_BLOCK_PAPI');
                if (triggerBlocks.length > 1) {
                    // 删除多余的块
                    for (let i = 1; i < triggerBlocks.length; i++) {
                        triggerBlocks[i].dispose(false, true);
                    }
                }

                allBlocks.forEach(block => {
                    if ((block.type === "MESSAGE_SET_CHAT_FORMAT" || block.type === "MESSAGE_GET_CHAT_MESSAGE") &&
                        (this.baseEvent !== "AsyncPlayerChatEvent" && this.baseEvent !== "PlayerChatEvent")) {
                        block.setWarningText(this.$t("pages.error_player_msg"))
                    }
                    if ((block.type === "MESSAGE_GET_COMMAND") &&
                        this.baseEvent !== "PlayerCommandPreprocessEvent") {
                        block.setWarningText(this.$t("pages.error_player_command"))
                    }
                    if (block.type === "TRIGGER_BLOCK" || block.type === "TRIGGER_BLOCK_PAPI") {
                        block.setDeletable(false)
                    }
                    if (block.type === "SERVER_SET_MOTD" && this.baseEvent !== "PlayerCommandPreprocessEvent") {
                        block.setWarningText(this.$t("pages.error_player_server"))
                    }
                    if ((block.type === "LOCATION_GET_FROM" || block.type === "LOCATION_GET_TO") &&
                        this.baseEvent !== "PlayerMoveEvent") {
                        block.setWarningText(this.$t("pages.error_player_move"))
                    }
                    if ((block.type === "EVENT_GET_LOCALE") &&
                        this.baseEvent !== "PlayerLocaleChangeEvent") {
                        block.setWarningText(this.$t("pages.error_player_lang"))
                    }
                    if ((block.type === "PLAYER_GET_KILLER") &&
                        this.baseEvent !== "PlayerDeathEvent") {
                        block.setWarningText(this.$t("pages.error_player_kill"))
                    }
                    if ((block.type === "PLAYER_GET_ENCHANTER") &&
                        this.baseEvent !== "EnchantItemEvent") {
                        block.setWarningText(his.$t("pages.error_player_item"))
                    }


                })
                this.generateCode();
            });
            const listCreateMutator = {
                itemCount_: 0,

                mutationToDom: function () {
                    const container = Blockly.utils.xml.createElement('mutation');
                    container.setAttribute('items', this.itemCount_);
                    return container;
                },
                domToMutation: function (xmlElement) {
                    const targetCount = parseInt(xmlElement.getAttribute('items'), 10);
                    this.updateShape_(targetCount);
                },

                saveExtraState: function () {
                    return {
                        itemCount: this.itemCount_,
                    };
                },
                loadExtraState: function (state) {
                    this.updateShape_(state['itemCount']);
                },

                updateShape_: function (targetCount) {
                    while (this.itemCount_ < targetCount) {
                        this.addPart_();
                    }
                    while (this.itemCount_ > targetCount) {
                        this.removePart_();
                    }
                    this.updateMinus_();
                },

                plus: function () {
                    this.addPart_();
                    this.updateMinus_();
                },

                minus: function () {
                    if (this.itemCount_ == 0) {
                        return;
                    }
                    this.removePart_();
                    this.updateMinus_();
                },
                addPart_: function () {
                    if (this.itemCount_ == 0) {
                        this.removeInput('EMPTY');
                        this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
                            .appendField(createPlusField(), 'PLUS')
                            .appendField(Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH']);
                    } else {
                        this.appendValueInput('ADD' + this.itemCount_);
                    }
                    this.itemCount_++;
                },
                removePart_: function () {
                    this.itemCount_--;
                    this.removeInput('ADD' + this.itemCount_);
                    if (this.itemCount_ == 0) {
                        this.topInput_ = this.appendDummyInput('EMPTY')
                            .appendField(createPlusField(), 'PLUS')
                            .appendField(Blockly.Msg['LISTS_CREATE_EMPTY_TITLE']);
                    }
                },
                updateMinus_: function () {
                    const minusField = this.getField('MINUS');
                    if (!minusField && this.itemCount_ > 0) {
                        this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
                    } else if (minusField && this.itemCount_ < 1) {
                        this.topInput_.removeField('MINUS');
                    }
                },
            };

            const listCreateHelper = function () {
                this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
                this.updateShape_(3);
            };

            /* Blockly.Extensions.registerMutator(
                 'new_list_create_with_mutator',
                 listCreateMutator,
                 listCreateHelper,
             );*/
        },
        async getUserTriggerList() {
            let fileUuid = this.fileUuid
            let {data} = await getUserTriggerListApi({
                fileUuid,
                triggerType: this.triggerTypeList[this.selSpace].type
            })
            this.userTriggerList = data
        },
        openCreateTrigger() {
            if (this.triggerSelIndex !== "" && this.checkChange) {
                this.triggerSelIndex = ""
                this.$confirm(this.$t("pages.error_next_event"), this.$t("pages.tips"), {
                    confirmButtonText:  this.$t("pages.save"),
                    cancelButtonText:  this.$t("pages.do_not_save"),
                    type: 'warning'
                }).then(() => {
                    this.saveToServe()
                    this.resetWorkspace()
                }).catch(() => {
                    this.resetWorkspace()
                });
            }
            this.trigger = {
                fileUuid: "",
                type: "",
                name: "",
                description: ""
            }
            this.trigger.fileUuid = this.fileUuid
            this.getTriggerComponentList()
            switch (this.selSpace) {
                case 0:
                    this.createEvent = true
                    break;
                case 1:
                    this.createPAPI = true
                    break;
                case 2:
                    this.trigger.type = "PlayerChatEvent"
                    break;
                default:
                    break
            }


        },
        async createTrigger() {
            let triggerTypeListElement = this.triggerTypeList[this.selSpace];
            if (!this.trigger.type && this.selSpace === 0) {
                this.$message.error( this.$t("pages.select_event_type"))
                return
            }
            if (!this.trigger.type && this.selSpace === 1) {
                this.$message.error(this.$t("pages.please_enter_a_logo"))
                return
            }
            if (!this.trigger.name) {
                this.$message.error(this.$t("pages.input_name"))
                return
            }
            this.trigger.triggerType = triggerTypeListElement.type
            await createTriggerApi(this.trigger)
            this.$message.success(this.$t("pages.create_successfully"))
            this.getUserTriggerList()
            this.createEvent = false
            this.createPAPI = false
            this.triggerSelIndex = ""
        },
        async getTriggerComponentList() {
            let {data} = await getTriggerComponentListApi({
                type: "EVENT"
            })
            this.triggerComponentList = data
        },
        resetWorkspace() {
            if (this.workspace) {
                Blockly.getMainWorkspace().dispose();
                this.workspace = null;
            }
        },
        choiceTrigger(index, item, e) {
            if (e.target._prevClass === "el-icon-delete-solid") {
                this.deleteTrigger(item.uuid, item.name)
                return;
            }
            if (this.triggerSelIndex !== "" && this.checkChange) {
                this.$confirm(this.$t("pages.error_next_event"), this.$t("pages.tips"), {
                    confirmButtonText: this.$t("pages.save"),
                    cancelButtonText: this.$t("pages.next_save"),
                    type: 'warning'
                }).then(() => {
                    this.saveToServe()
                    this.switchTrigger(index, item)
                }).catch(() => {
                    this.switchTrigger(index, item)
                });
            } else {
                this.switchTrigger(index, item)
            }

        },
        switchTrigger(index, item) {
            this.resetWorkspace()
            this.resetItem()
            this.triggerSelIndex = index
            this.triggerItem.triggerUuid = item.uuid
            this.triggerItem.description = item.description
            this.triggerItem.enable = item.enable
            this.triggerItem.schema = item.schema
            this.baseEvent = item.type
            this.checkChange = false
            this.saveCodeJson = gzipUtil.decompressFromBase64(item.schema)
            this.initializeBlockly()
        },
        async saveToServe() {
            this.saveCode()
            this.generateCode()
            let triggerItem = JSON.parse(JSON.stringify(this.triggerItem))
            await saveAndUpdateTriggerApi(triggerItem)
            this.getUserTriggerList()
            this.$message.success(this.$t("pages.saved_successfully"))
        },
        deleteTrigger(uuid, name) {
            this.$confirm(`此操作将永久删除 ${name} 触发器, 是否继续?`, '提示', {
                confirmButtonText: this.$t("pages.ok"),
                cancelButtonText: this.$t("pages.cancel"),
                type: 'warning'
            }).then(async () => {
                await deleteTriggerApi({triggerUuid: uuid})
                this.triggerSelIndex = ""
                this.getUserTriggerList()
                this.resetWorkspace()
                this.resetItem()
                this.$message.success(this.$t("pages.delete_success"))
            }).catch(() => {
            });


        },
        resetItem() {
            this.triggerItem = {
                triggerUuid: "",
                description: "",
                enable: "",
                schema: "",
                expression: "",
            }
            this.baseEvent = ""
        },

    }
}
